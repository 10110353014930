import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import SelectedMessageView from './selected-message.view';
import { EtcApi } from '../../../../../apis/v1/etc.api';

import useAuthentication from '../../../../../store/useAuthentication';

const SelectedMessage = () => {
  const { authentication } = useAuthentication();

  const etcApi = new EtcApi(process.env.REACT_APP_ENV !== 'dev', authentication.accessToken, authentication.deviceId);
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectedMessage, setSelectedMessage] = useState<any>('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (selectedMessage.length !== 0 && authentication.accessToken.length !== 0) {
      setIsLoading(false);
    }
  }, [selectedMessage, authentication.accessToken]);

  const handleGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (authentication.accessToken && authentication.deviceId) {
      const fetchData = async () => {
        try {
          const { data, status }: any = await etcApi.getMessagesDetailsAsync({ param: { id: Number(id) } });
          setSelectedMessage(data);
        } catch (error) {
          // Handle error
          // await window.flutter_inappwebview.callHandler('goErrorPage', '');
        }
      };

      fetchData();
    }
  }, [authentication.accessToken, authentication.deviceId]);

  const props = {
    handleGoBack,
    selectedMessage,
    isLoading,
  };
  return <SelectedMessageView {...props} />;
};

export default SelectedMessage;
