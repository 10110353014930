import HeadView from '../../../../molecules/common/head.view';
import TypographyView from '../../../../atoms/foundation/typography.view';
import { css } from '@emotion/react';
import Colors from '../../../../../types/colors';
import BackArrow from '../../../../../assets/images/svg/icons_arrow_back.svg';
import Icon from '../../../../atoms/foundation/icon.view';
import React from 'react';
import MESSAGES from '../../../../../constants/messages';
import convertDate from '../../../../../utils/dateConverter';
import NoMessageView from '../../../../atoms/alert/no-message.view';
import { ImageEmptyMessage } from '../../../../../assets/images/svg';
import LoadingSpinner from '../../../../atoms/foundation/loading-spinner.view';
import useDpi from '../../../../../hooks/useDpi';

interface MoreQuestionDetailsViewProps {
  questionDetailData: any;
  handleGoBack: () => void;
  handleQuestionDelete: () => void;
  isLoading: boolean;
  managerAnswer: any;
}

const MoreQuestionDetailsView = ({ questionDetailData, handleGoBack, handleQuestionDelete, isLoading, managerAnswer }: MoreQuestionDetailsViewProps) => {
  const { miniFontSize, subFontSize, secondaryFontSize, miniIcons } = useDpi();
  const top = css`
    height: 50px;
    background-color: ${Colors.white_regular};
  `;
  const wrapper = css`
    width: 100%;
    height: 100vh;
    background-color: ${Colors.Grey_01_bg};
  `;

  const questionWrapper = css`
    padding: 24px 18px 40px 21px;
    background-color: ${Colors.white_regular};
  `;

  const date = css`
    color: ${Colors.Grey_03_regular};
    font-size: ${miniFontSize}px;
    padding-bottom: 6.7px;
  `;

  const title = css`
    padding-bottom: 13.3px;
  `;

  const typography = css`
    border-bottom: 1px solid ${Colors['Grey_02_line']};
    border-top: 1px solid ${Colors['Grey_02_line']};
    padding: 9.7px 21px 9.3px 21px;
  `;

  const timeWrapper = css`
    display: flex;
    justify-content: space-between;
    padding: 13.3px 21px;
  `;

  const content = css`
    padding: 0 21px !important;
    font-size: ${subFontSize}px;
  `;
  const spinnerWrapper = css`
    display: flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    align-items: center;
    margin-top: 130px;
  `;
  return (
    <div css={wrapper}>
      <div css={top} />
      <div>
        <HeadView
          handleClick={handleGoBack}
          isDelete={true}
          icon={<Icon src={BackArrow} width={`${miniIcons}px`} height={`${miniIcons}px`} />}
          header={MESSAGES.HEAD_TITLES.QNA}
          handleAction={handleQuestionDelete}
        />
        {isLoading ? (
          <div css={spinnerWrapper}>
            <LoadingSpinner />
          </div>
        ) : (
          <>
            {!questionDetailData ? (
              <>
                <NoMessageView icon={ImageEmptyMessage} text={MESSAGES.INFORMATION.EMPTY_QNA} height={'83vh'} />
              </>
            ) : (
              <div>
                <div css={questionWrapper}>
                  <TypographyView cssStyles={date}>{convertDate(questionDetailData?.vocAt)}</TypographyView>
                  <TypographyView cssStyles={title} fontSize={secondaryFontSize} bold={true}>
                    {questionDetailData?.vocTitle}
                  </TypographyView>
                  <TypographyView>{questionDetailData?.vocContent}</TypographyView>
                </div>
                <div>
                  <TypographyView cssStyles={typography} bold={true} color={'Grey_04_inactive'} fontSize={subFontSize} children={MESSAGES.ROW_TITLES.ADMIN_ANSWER} />
                  <>
                    {questionDetailData?.managerAnswerAt?.includes(':') ? (
                      <>
                        <div css={timeWrapper}>
                          <TypographyView cssStyles={date}>{convertDate(questionDetailData?.managerAnswerAt)}</TypographyView>
                          <TypographyView cssStyles={date}>답변자: {questionDetailData?.managerName}</TypographyView>
                        </div>
                        {managerAnswer.map((line: string, index: number) => (
                          <TypographyView key={index} cssStyles={content}>
                            {line}
                          </TypographyView>
                        ))}
                      </>
                    ) : (
                      <TypographyView cssStyles={content}>{MESSAGES.PLACEHOLDER.NEEDS_ADMIN_ANSWER}</TypographyView>
                    )}
                  </>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default MoreQuestionDetailsView;
