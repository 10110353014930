import { AxiosResponse } from 'axios';
import { AxiosConfig, axiosErrorExceptionAsync, AxiosRequest } from '../axios.config';

export type PostDuplicationLoginIdRequest = {
  loginId: string;
};

export type PostDuplicationLoginIdResponse = {
  state: boolean;
};

export type GetLoginIdRequest = {
  nm: string;
  brdt: string;
  gender: string;
  telno: string;
};

export type GetLoginIdResponse = {
  dataList: {
    loginId: string;
    userNm: string;
    genderType: string;
    userType: string;
    familyNm: string;
  }[];
};

export type PostSignInBodyRequest = {
  loginId: string;
  password: string;
};

export type PostSignInHeaderRequest = {
  AppVersion: string | undefined;
  OsType: string;
  PushToken: string;
};
export type PatchPasswordRequest = {
  password: string;
};

export type PatchPasswordEngineRequest = {
  loginId: string;
  password: string;
  authTokenDto: {
    authToken: string;
  };
};

export type PostSignInResponse = {
  tokenType: string;
  accessToken: string;
  accessExpiredIn: string;
  refreshToken: string;
  refreshExpiresIn: string;
};

export type PostAccountCertificationRequest = {
  userNm: string;
  userBrdt: string;
  userGender: string;
  userCpTelno: string;
};
export type PostAccountPasswordCertificationResponse = {
  password: string;
};
export type PostAccountCertificationResponse = {
  message: {
    title: string;
    content: string;
  };
};

export class AccountAPI extends AxiosConfig {
  private _prefix = '/v1';
  private _url = `${this._prefix}/account`;

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(useBaseURL?: boolean, token?: string, device?: string) {
    super(useBaseURL, token, device);
  }

  /**
   * 01. 아이디 중복 검사 API
   * @see https://chc-app-api.huraydev.net/swagger-ui/index.html
   * @version 1.0.0
   * @param body
   */
  public postDuplicationLoginIdAsync = async ({
    body,
  }: AxiosRequest<any, any, PostDuplicationLoginIdRequest>): Promise<AxiosResponse<PostDuplicationLoginIdResponse> | AxiosResponse<unknown, any>> => {
    try {
      return await this.instance.post<PostDuplicationLoginIdResponse>(`${this._url}/duplication/login-id`, {
        data: body,
      });
    } catch (error) {
      return axiosErrorExceptionAsync(error);
    }
  };

  /**
   * 02. 아이디 찾기 API
   * @see https://chc-app-api.huraydev.net/swagger-ui/index.html
   * @version 1.0.0
   * @param query
   */
  public getLoginIdAsync = async ({ query }: AxiosRequest<any, GetLoginIdRequest>): Promise<AxiosResponse<GetLoginIdResponse> | AxiosResponse<unknown, any>> => {
    try {
      return await this.instance.get<GetLoginIdResponse>(`${this._url}/login-id`, {
        params: query,
      });
    } catch (error) {
      return axiosErrorExceptionAsync(error);
    }
  };

  /**
   * 04. 아이디 찾기 API - 토큰 재발급
   * @see https://chc-app-api.huraydev.net/swagger-ui/index.html
   * @version 1.0.0
   */
  public postTokenRefreshAsync = async (): Promise<AxiosResponse<PostDuplicationLoginIdResponse> | AxiosResponse<unknown, any>> => {
    try {
      return await this.instance.post<PostDuplicationLoginIdResponse>(`${this._url}/token/refresh`);
    } catch (error) {
      return axiosErrorExceptionAsync(error);
    }
  };

  /**
   * 04. 아이디 찾기 API - 로그인
   * @see https://chc-app-api.huraydev.net/swagger-ui/index.html
   * @version 1.0.0
   * @param body
   * @param header
   */
  public postSignInAsync = async ({
    body,
    header,
  }: AxiosRequest<any, any, PostSignInBodyRequest, PostSignInHeaderRequest>): Promise<AxiosResponse<PostSignInResponse> | AxiosResponse<unknown, any>> => {
    try {
      return await this.instance.post<PostSignInResponse>(
        `${this._url}/sign-in`,
        {
          ...body,
        },
        {
          headers: header,
        }
      );
    } catch (error) {
      return axiosErrorExceptionAsync(error);
    }
  };
  public postSignOutAsync = async (): Promise<AxiosResponse<any> | AxiosResponse<unknown, any>> => {
    try {
      return await this.instance.post(`${this._url}/sign-out`);
    } catch (error) {
      return axiosErrorExceptionAsync(error);
    }
  };
  public patchPasswordAuthAsync = async ({ body }: AxiosRequest<any, any, PatchPasswordRequest>): Promise<AxiosResponse<PostSignInResponse> | AxiosResponse<unknown, any>> => {
    try {
      return await this.instance.patch<PostSignInResponse>(`${this._url}/password-authentication`, {
        ...body,
      });
    } catch (error) {
      return axiosErrorExceptionAsync(error);
    }
  };
  public patchPasswordEngineAuthAsync = async ({ body }: AxiosRequest<any, any, PatchPasswordEngineRequest>): Promise<AxiosResponse<PostSignInResponse> | AxiosResponse<unknown, any>> => {
    try {
      return await this.instance.patch<PostSignInResponse>(`${this._url}/password`, {
        ...body,
      });
    } catch (error) {
      return axiosErrorExceptionAsync(error);
    }
  };
  public postAccountCertificationAuthAsync = async ({
    body,
  }: AxiosRequest<any, any, PostAccountCertificationRequest>): Promise<AxiosResponse<PostAccountCertificationRequest> | AxiosResponse<unknown, any>> => {
    try {
      return await this.instance.post<PostAccountCertificationResponse>(`${this._url}/certification`, {
        ...body,
      });
    } catch (error) {
      return axiosErrorExceptionAsync(error);
    }
  };
  public postAccountPasswordCertificationAuthAsync = async ({ body }: AxiosRequest<any, any, any>): Promise<AxiosResponse<any> | AxiosResponse<unknown, any>> => {
    try {
      return await this.instance.post<PostAccountPasswordCertificationResponse>(`${this._url}/password/certification`, {
        ...body,
      });
    } catch (error) {
      return axiosErrorExceptionAsync(error);
    }
  };
}
