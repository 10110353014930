import { AxiosResponse } from 'axios';
import { AxiosConfig, axiosErrorExceptionAsync, AxiosRequest } from '../axios.config';

export type PostRequest = {
  smsRqstType: 'forgot_password' | 'forgot_auth_code' | 'sigup';
  telNo: string;
};

export type PatchRequest = {
  smsRqstType: string;
  rqstSeq: string;
  certCd: string;
};

export class SmsAPI extends AxiosConfig {
  private _prefix = '/v1';
  private _url = `${this._prefix}/sms`;

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(useBaseURL?: boolean, token?: string, device?: string) {
    super(useBaseURL, token, device);
  }

  /**
   * 10. SMS API - SMS 인증문자 요청
   * @see https://chc-app-api.huraydev.net/swagger-ui/index.html
   * @version 1.0.0
   */
  public postAsync = async ({ body }: AxiosRequest<any, any, PostRequest>): Promise<AxiosResponse<any> | AxiosResponse<unknown, any>> => {
    try {
      return await this.instance.post<any>(`${this._url}/certification-number`, {
        ...body,
      });
    } catch (error) {
      return axiosErrorExceptionAsync(error);
    }
  };

  /**
   * 10. SMS API - SMS 인증문자 확인
   * @see https://chc-app-api.huraydev.net/swagger-ui/index.html
   * @version 1.0.0
   */
  public patchAsync = async ({ body }: AxiosRequest<any, any, PatchRequest>): Promise<AxiosResponse<any> | AxiosResponse<unknown, any>> => {
    try {
      return await this.instance.patch<any>(`${this._url}/certification-number`, {
        ...body,
      });
    } catch (error) {
      return axiosErrorExceptionAsync(error);
    }
  };
}
