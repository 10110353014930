import React from 'react';
import HeadView from '../../../molecules/common/head.view';
import Icon from '../../../atoms/foundation/icon.view';
import { ArrowBack } from '../../../../assets/images/svg';
import { css } from '@emotion/react';
import TypographyView from '../../../atoms/foundation/typography.view';
import { LogoImage } from '../../../../assets/images/svg';
import useDpi from '../../../../hooks/useDpi';
import useAuthentication from '../../../../store/useAuthentication';
import Colors from '../../../../types/colors';
interface MoreVersionViewProps {
  handleGoBack: () => void;
}

const MoreVersionView = ({ handleGoBack }: MoreVersionViewProps) => {
  const { miniIcons, imageSize, headerFontSize } = useDpi();
  const { authentication } = useAuthentication();
  const top = css`
    height: 50px;
    background-color: ${Colors.white_regular};
  `;
  const wrapper = css`
    width: 100%;
    background-color: white;
  `;

  const bodyWrapper = css`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: calc(100vh - 110px);
  `;
  return (
    <div css={wrapper}>
      <div css={top} />
      <HeadView handleClick={handleGoBack} icon={<Icon src={ArrowBack} width={`${miniIcons}px`} height={`${miniIcons}px`} />} header={'Version'} />
      <div css={bodyWrapper}>
        <Icon src={LogoImage} width={`${imageSize}px`} height={`${imageSize}px`} />
        <TypographyView fontSize={headerFontSize} bold={true}>
          {authentication.appVersion}
        </TypographyView>
      </div>
    </div>
  );
};

export default MoreVersionView;
