import React from 'react';
import { Link } from 'react-router-dom';
import ButtonView from '../../atoms/inputs/button.view';
import { css } from '@emotion/react';
import MESSAGES from '../../../constants/messages';
import useViewportWidth from '../../../hooks/useViewportWidth';
import Icon from '../../atoms/foundation/icon.view';
import { ErrorImage } from '../../../assets/images/svg';
import useDpi from '../../../hooks/useDpi';
const NoMatchPage = () => {
  const wrapper = css`
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    text-align: center;
    gap: 15px;
    h4 {
      margin: 0;
    }
    p {
      margin: 0;
    }
  `;
  const link = css`
    text-decoration: none;
    position: absolute;
    bottom: 0;
    width: 100%;
  `;
  const viewportWidth = useViewportWidth();
  const { imageSize } = useDpi();

  return (
    <div css={wrapper}>
      <div>
        <Icon src={ErrorImage} width={`${imageSize}`} height={`${imageSize}`} />
      </div>
      <h4>{MESSAGES.INFORMATION.ERROR_INFO}</h4>
      <p>
        {MESSAGES.INFORMATION.ERROR_INFO_SUB.TOP}
        {viewportWidth < 768 ? <br /> : <>&nbsp;</>}
        {MESSAGES.INFORMATION.ERROR_INFO_SUB.BOTTOM}
      </p>
      <Link to="/" css={link}>
        <ButtonView variant={'primary'} text={MESSAGES.BUTTON.GO_BACK} rounded={'0'} size={56} width={'100%'} />
      </Link>
    </div>
  );
};

export default NoMatchPage;
