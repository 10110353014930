import React from 'react';
import ButtonView from '../../../atoms/inputs/button.view';
import { css } from '@emotion/react';
import Colors from '../../../../types/colors';
import useDpi from '../../../../hooks/useDpi';

interface ReportPartsNavbarViewProps {
  activeButton: string;
  handleButtonClick: any;
  filteredData: any;
  combinedWidth: any;
  viewport: any;
}

const ReportPartsNavbarView = ({ filteredData, activeButton, handleButtonClick, combinedWidth, viewport }: ReportPartsNavbarViewProps) => {
  const { subFontSize } = useDpi();
  const navbarTab = css`
    display: flex;
    justify-content: flex-start;
    list-style-type: none;
    padding: 0;
    text-align: center;
    overflow-x: auto; /* added */
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none;
    overflow-y: scroll;
    margin: 0;
    ::-webkit-scrollbar {
      display: none;
    }
    a {
      text-decoration: none;
      color: ${Colors['Grey_03_regular']};
      white-space: nowrap; /* added */
    }
    a.active {
      color: black;
      font-weight: bold;
      button {
        border-bottom: 2px solid ${Colors['Primary_100_basic']};
      }
    }
  `;

  const LI = css`
    width: ${combinedWidth > viewport ? 'auto' : '100%'};
    min-width: ${combinedWidth > viewport && '95px'};
  `;

  const buttonStyles = (value: any) => css`
    color: ${activeButton === value ? `${Colors['Black_basic']}` : `${Colors['Grey_03_regular']}`};
    border-bottom: ${activeButton === value ? `2px solid ${Colors['Primary_100_basic']}` : 'none'};
    font-weight: 600;
    line-height: 18px;
    height: 60px;
    font-size: ${subFontSize}px;
    padding: 1px 0;
  `;
  return (
    <div style={{ width: '100%' }}>
      <nav style={{ padding: '0 1px' }}>
        <ul css={navbarTab}>
          {filteredData?.map((button: { value: React.Key | null | undefined; text: string }, index: number) => (
            <li key={index} css={LI}>
              <ButtonView
                variant={'secondary'}
                text={button.text.length > 5 ? button.text.slice(0, 5) + '...' : button.text}
                cssStyles={buttonStyles(button.value)}
                rounded={'0'}
                handleClick={() => handleButtonClick(button.value)}
              />
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default ReportPartsNavbarView;
