import React, { ReactElement } from 'react';
import TypographyView from '../foundation/typography.view';
import { css } from '@emotion/react';
import Colors from '../../../types/colors';
import Icon from '../foundation/icon.view';
import useDpi from '../../../hooks/useDpi';
interface NoMessageViewProps {
  text?: string;
  icon?: any;
  height?: string;
}

const NoMessageView = ({ text = '도착한 메시지가 없습니다.', icon, height }: NoMessageViewProps) => {
  const { iconSize } = useDpi();
  const wrapper = css`
    display: flex;
    background-color: ${Colors['Grey_01_bg']};
    flex-direction: column;
    height: ${height || 'calc(100vh - 100px)'};
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 10px;
  `;
  return (
    <div css={wrapper}>
      <Icon src={icon} width={`${iconSize}px`} height={`${iconSize}px`} />
      <TypographyView color={'Grey_05_subtext'}>{text}</TypographyView>
    </div>
  );
};

export default NoMessageView;
