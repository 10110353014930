import * as React from 'react';
import { css } from '@emotion/react';
import Dashboard from '../../organisms/dashboard/dashboard';

interface DashboardViewProps {
  dashboardPageData: any;
  reportPageData: any;
  isLoading: boolean;
}

const DashboardPageView = ({ dashboardPageData, reportPageData, isLoading }: DashboardViewProps) => {
  const wrapper = css`
    width: 100%;
    height: 100%;
    overflow: hidden;
    //padding-top: 50px;
  `;

  const props = {
    reportPageData,
    dashboardPageData,
    isLoading,
  };
  return (
    <div css={wrapper}>
      <Dashboard {...props} />
    </div>
  );
};
export default DashboardPageView;
