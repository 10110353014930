import { AxiosResponse } from 'axios';
import { AxiosConfig, axiosErrorExceptionAsync } from '../axios.config';

export type GetResponse = {
  currentYearSurveyCompletionStatus: string;
  dataList: any[];
  nm: string;
  nthCurrentYear: number;
  phase: number;
  phaseTotal: number;
};

export class DashboardAPI extends AxiosConfig {
  private _prefix = '/v1';
  private _url = `${this._prefix}/dashboard`;

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(useBaseURL?: boolean, token?: string, device?: string) {
    super(useBaseURL, token, device);
  }

  /**
   * 07. 대시보드 API - 대시보드 조회
   * @see https://chc-app-api.huraydev.net/swagger-ui/index.html
   * @version 1.0.0
   */
  public getAsync = async (): Promise<AxiosResponse<GetResponse> | AxiosResponse<unknown, any>> => {
    try {
      return await this.instance.get<GetResponse>(`${this._url}`);
    } catch (error) {
      return axiosErrorExceptionAsync(error);
    }
  };
}
