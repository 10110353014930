import React from 'react';

import { css } from '@emotion/react';
import Colors from '../../../../types/colors';
import HeadView from '../../../molecules/common/head.view';
import Icon from '../../../atoms/foundation/icon.view';
import BackArrow from '../../../../assets/images/svg/icons_arrow_back.svg';
import MESSAGES from '../../../../constants/messages';
import Messages from '../../../molecules/dashboard/alarm/messages';
import QuestionAndAnswer from '../../../molecules/dashboard/alarm/question-and-answer';
import LoadingSpinner from '../../../atoms/foundation/loading-spinner.view';
import useDpi from '../../../../hooks/useDpi';

interface DashboardAlarmViewProps {
  handleGoBack: () => void;
  activeNavbar: string;
  handleNavbar: (navbar: string) => void;
  alarmData: any;
  isLoading: boolean;
  height: any;
}
interface SubNavbar {
  text: string;
  value: string;
}
const subNavbar: SubNavbar[] = [
  { text: MESSAGES.HEAD_TITLES.MESSAGE, value: 'messages' },
  { text: MESSAGES.HEAD_TITLES.QNA, value: 'qna' },
];

const DashboardAlarmView = ({ activeNavbar, handleGoBack, handleNavbar, alarmData, isLoading, height }: DashboardAlarmViewProps) => {
  const { miniIcons } = useDpi();
  const top = css`
    overflow: hidden;
    position: fixed;
    top: 0;
    height: 50px;
    width: 100%;
    z-index: 1;
    background-color: ${Colors['white_regular']};
  `;
  const wrapper = css`
    overflow: hidden;
    width: 100%;
    //height: 100vh;
    background-color: ${Colors['Grey_01_bg']};
    overflow-y: scroll;
    scroll-behavior: smooth;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
  `;

  const headAndNavbarWrapper = css`
    position: fixed;
    z-index: 1;
    top: 50px;
    width: 100%;
    background-color: ${Colors['white_regular']};
  `;

  const messagesTab = css`
    display: flex;
    justify-content: space-around;
    font-size: 15px;
    list-style-type: none;
    padding: 0;
    margin-bottom: 0;
    text-align: center;
    background-color: ${Colors['white_regular']};

    li {
      width: 100%;
      color: ${Colors['Grey_03_regular']};
    }
    .active {
      padding-bottom: 12px;
      color: black;
      font-weight: bold;
      border-bottom: 2px solid ${Colors['Primary_100_basic']};
    }
  `;

  const messagesWrapper = css`
    background-color: ${Colors['white_regular']};
    padding-top: 155px;
  `;
  const spinnerWrapper = css`
    display: flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    align-items: center;
    margin-top: 130px;
  `;

  return (
    <>
      <div css={wrapper}>
        <div css={top} />
        <div style={{ backgroundColor: 'white' }}>
          <div css={headAndNavbarWrapper}>
            <HeadView handleClick={handleGoBack} icon={<Icon src={BackArrow} width={`${miniIcons}px`} height={`${miniIcons}px`} />} header={MESSAGES.HEAD_TITLES.ALARM} />
            <nav>
              <ul css={messagesTab}>
                {subNavbar.map((navbar, index) => (
                  <li key={index} className={activeNavbar === navbar.value ? 'active' : ''} onClick={() => handleNavbar(navbar.value)}>
                    {navbar.text}
                  </li>
                ))}
              </ul>
            </nav>
          </div>

          <div className={'message-wrapper'} css={messagesWrapper}>
            {activeNavbar === 'messages' &&
              (isLoading ? (
                <div css={spinnerWrapper}>
                  <LoadingSpinner />
                </div>
              ) : (
                <Messages height={height} data={alarmData?.messageList} />
              ))}
            {activeNavbar === 'qna' &&
              (isLoading ? (
                <div css={spinnerWrapper}>
                  <LoadingSpinner />{' '}
                </div>
              ) : (
                <QuestionAndAnswer data={alarmData?.vocList} />
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardAlarmView;
