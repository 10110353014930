import React from 'react';
import { css } from '@emotion/react';
import Colors from '../../../types/colors';
import TypographyView from '../../atoms/foundation/typography.view';
import MESSAGES from '../../../constants/messages';
import useDpi from '../../../hooks/useDpi';
import ButtonView from '../../atoms/inputs/button.view';
interface BarInfoViewProps {
  userName?: string;
  buildDomToImage?: () => void;
}

const BarInfoView = ({ userName, buildDomToImage }: BarInfoViewProps) => {
  const { headerFontSize, subFontSize } = useDpi();
  const wrapper = css`
    display: flex;
    flex-direction: column;
    margin: 0 10px;
  `;
  const tabWrapper = css`
    display: flex;
    flex-direction: row;
    background-color: ${Colors['Grey_02_line']};
    border-radius: 13px;
    padding: 0 1rem;
    justify-content: space-between;
    font-size: 11px;
    color: ${Colors['Grey_03_regular']};
    height: 40px;
  `;
  const colorWrapper = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3px;
    font-size: ${subFontSize}px;
  `;
  const good = css`
    width: 10px;
    height: 10px;
    background-color: green;
    border-radius: 3px;
  `;
  const normal = css`
    width: 10px;
    height: 10px;
    background-color: orange;
    border-radius: 3px;
  `;
  const bad = css`
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 3px;
  `;

  const typography = css`
    margin: 15px 0;
    font-size: ${headerFontSize}px;
  `;

  const Flex = css`
    display: flex;
    gap: 15px;
  `;

  return (
    <div css={wrapper}>
      {userName && (
        <TypographyView bold={true} cssStyles={typography}>
          {userName}
          {MESSAGES.INFORMATION.KEY_REPORT}
        </TypographyView>
      )}
      <div css={tabWrapper}>
        <div css={Flex}>
          <div css={colorWrapper}>
            <div css={good} />
            <span>{MESSAGES.INFORMATION.BAR_INFO_GOOD}</span>
          </div>
          <div css={colorWrapper}>
            <div css={normal} />
            <span>{MESSAGES.INFORMATION.BAR_INFO_NORMAL}</span>
          </div>
          <div css={colorWrapper}>
            <div css={bad} />
            <span>{MESSAGES.INFORMATION.BAR_INFO_BAD}</span>
          </div>
        </div>
        <div className="save_image_btn" css={colorWrapper}>
          {buildDomToImage && <ButtonView handleClick={buildDomToImage} text="결과 다운로드" variant="secondary" size={26} />}
        </div>
      </div>
    </div>
  );
};

export default BarInfoView;
