import React from 'react';
import NoDashboardDataView from '../../atoms/alert/no-dashboard-data.view';
import BarInfoView from '../../molecules/common/bar-info-view';
import BarChartSectionView from '../../molecules/common/bar-chart-section.view';
import Icon from '../../atoms/foundation/icon.view';
import { DashboardBtnMore } from '../../../assets/images/svg';
import { css } from '@emotion/react';
import Colors from '../../../types/colors';
import SurveyInfoSection from '../../molecules/common/survey-info-section';
import { Link } from 'react-router-dom';
import iconMap from '../../../assets/images/iconMap';
import LoadingSpinner from '../../atoms/foundation/loading-spinner.view';
import useDpi from '../../../hooks/useDpi';

interface DashboardViewProps {
  dashboardPageData: any;
  reportPageData: any;
  surveyNameMap: any;
  isLoading: boolean;
}

const DashboardView = ({ dashboardPageData, surveyNameMap, isLoading }: DashboardViewProps) => {
  const { iconSize, plusIcon } = useDpi();
  const wrapper = css`
    display: flex;
    flex-direction: column;
    background-color: ${Colors['Grey_01_bg']};
    width: 100%;
    height: 100%;
  `;
  const spinnerWrapper = css`
    display: flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    align-items: center;
    margin-top: 130px;
  `;
  const BarData2: any = [
    {
      surveyType: 'MEASURE_B',
      graphCd: 'PAR_B_2',
      graphNm: '피로 (Fatigue)',
      userType: 'P',
      maxValue: 10.0,
      colorLineInfo: {
        greenMaxLine: 3.0,
        orangeMaxLine: 6.0,
        redMaxLine: 10.0,
      },
      graph: [
        {
          nthYear: 1,
          color: 'GREEN',
          value: 0,
        },
      ],
    },
    {
      surveyType: 'MEASURE_B',
      graphCd: 'PAR_B_3',
      graphNm: '신체적 기능 (Physical Function)',
      userType: 'P',
      maxValue: 100.0,
      colorLineInfo: {
        greenMaxLine: 100.0,
        orangeMaxLine: 66.0,
        redMaxLine: 33.0,
      },
      graph: [
        {
          nthYear: 1,
          color: 'GREEN',
          value: 100.0,
        },
      ],
    },
  ];
  return (
    <div css={wrapper}>
      <SurveyInfoSection />
      <BarInfoView userName={dashboardPageData.nm} />

      {isLoading ? (
        <div css={spinnerWrapper}>
          <LoadingSpinner />
        </div>
      ) : (
        <>
          {dashboardPageData?.dataList?.length !== 0 ? (
            dashboardPageData?.dataList?.map(
              (value: { surveyType: string; graphCd: string; graphNm: string; maxValue: number; graph: { nthYear: number; color: 'string'; value: number } }, index: number) => {
                const surveyName = surveyNameMap[value.surveyType];
                const koreanLetters = value.graphNm
                  .split('(')[0] // Remove everything after the opening parenthesis
                  .replace(/[^\uAC00-\uD7AF\u1100-\u11FF\u3130-\u318F]/g, ' ') // Replace non-Korean characters with a space
                  .replace(/\s+/g, ' ') // Replace multiple consecutive spaces with a single space
                  .trim(); // Trim leading and trailing spaces

                return (
                  <BarChartSectionView
                    key={index}
                    data={value}
                    iconName={koreanLetters}
                    icon={<Icon width={`${iconSize}px`} height={`${iconSize}px`} src={iconMap[value.graphCd]} />}
                    moreBtn={
                      <Link to={`/report/${value.surveyType}&${surveyName}`}>
                        <Icon src={DashboardBtnMore} width={`${plusIcon}px`} height={`${plusIcon}px`} />
                      </Link>
                    }
                  />
                );
              }
            )
          ) : (
            <NoDashboardDataView />
          )}
        </>
      )}
    </div>
  );
};

export default DashboardView;
