import React from 'react';
import ReportListView from '../../molecules/report/report-list.view';
import TypographyView from '../../atoms/foundation/typography.view';
import { css } from '@emotion/react';
import Colors from '../../../types/colors';
import MESSAGES from '../../../constants/messages';
import useDpi from '../../../hooks/useDpi';

interface ReportViewProps {
  reportPageData: any;
}

const ReportView = ({ reportPageData }: ReportViewProps) => {
  const { headerFontSize } = useDpi();
  const wrapper = css`
    height: calc(100vh - 50px);
    background-color: ${Colors['Grey_01_bg']};
  `;
  const title = css`
    display: flex;
    align-items: center;
    background-color: ${Colors['white_regular']};
    width: 100%;
    height: 66px;
  `;
  const report = css`
    margin-left: 21px;
  `;
  return (
    <div css={wrapper}>
      <div css={title}>
        <TypographyView bold={true} fontSize={headerFontSize} cssStyles={report}>
          {MESSAGES.HEAD_TITLES.REPORT}
        </TypographyView>
      </div>
      <ReportListView reportPageData={reportPageData} />
    </div>
  );
};

export default ReportView;
