import React, { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserIdentificationView from './profile-user-indetification.view';
import MESSAGES from '../../../../../../constants/messages';
import VALIDATION_MAP from '../../../../../../constants/validationMap';
import useAuthentication from '../../../../../../store/useAuthentication';
import { SmsAPI } from '../../../../../../apis/v1/sms.api';
import { AccountAPI } from '../../../../../../apis/v1/account.api';

const ProfileUserIdentification = () => {
  const navigate = useNavigate();
  const { authentication } = useAuthentication();
  const smsApi = new SmsAPI(process.env.REACT_APP_ENV !== 'dev', authentication.accessToken, authentication.deviceId);
  const accountApi = new AccountAPI(process.env.REACT_APP_ENV !== 'dev', authentication.accessToken, authentication.deviceId);
  const handleGoBack = () => {
    navigate('/more/profile/change-password');
  };
  const [name, setName] = useState('');
  const [birthDate, setBirthDate] = useState('선택');
  const [gender, setGender] = useState('');
  const [phNumber, setPhNumber] = useState('');
  const [engCode, setEngCode] = useState('');
  const [validationError, setValidationError] = useState('');
  const [phoneNumberDisabled, setPhoneNumberDisabled] = useState(true);
  const [engineCodeDisabled, setEngineCodeDisabled] = useState(true);
  const [verified, setVerified] = useState(false);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [rqstSeq, setRqstSeq] = useState('');
  const [startTimer, setStartTimer] = useState(false);
  const [openTimeElapsedAlert, setOpenTimeElapsedAlert] = useState(false);

  const handleCheckboxChange = (selectedGender: React.SetStateAction<any>) => {
    setGender(selectedGender === gender ? '' : selectedGender);
  };
  const openDatepicker = async () => {
    if (process.env.REACT_APP_BUILD === 'flutter') {
      // @ts-ignore
      const response = await window.flutter_inappwebview.callHandler('showOpenDatePicker', 'showOpenDatePickerFn');
      setBirthDate(response);
    } else {
      setBirthDate('2021-02-15');
    }
  };
  const handlePhNumber = async () => {
    try {
      const { data } = await smsApi.postAsync({ body: { smsRqstType: 'forgot_password', telNo: phNumber } });
      if (data?.code?.includes('ERR_401')) {
        await window.flutter_inappwebview.callHandler('goRefresh', '');
        // navigate('/index.html');
      } else if (data?.code?.includes('ERR_500')) {
        await window.flutter_inappwebview.callHandler('goErrorPage', '');
      } else if (data?.code === 'ERR-406-007') {
        await window.flutter_inappwebview.callHandler('goRefresh', '');
      } else if (data.code === 'ERR_400_000') {
        await window.flutter_inappwebview.callHandler('showToastMessage', '요청인자가 유효하지 않습니다.');
        return;
      } else if (data?.code) {
        await window.flutter_inappwebview.callHandler('showToastMessage', `${data?.message}`);
        return;
      } else {
        setRqstSeq(data?.rqstSeq);
        setStartTimer(true);
      }
      setPhoneNumberDisabled(true);
      setTimeout(() => {
        setPhoneNumberDisabled(false);
      }, 5000);
    } catch (error) {
      // await window.flutter_inappwebview.callHandler('goErrorPage', '');
    }
  };
  const handleEngineCode = async () => {
    try {
      const { data } = await smsApi.patchAsync({ body: { smsRqstType: 'forgot_loginid', rqstSeq: rqstSeq, certCd: engCode } });
      if (data?.code?.includes('ERR_500')) {
        await window.flutter_inappwebview.callHandler('goErrorPage', '');
      } else if (data.code === 'ERR_400_000') {
        await window.flutter_inappwebview.callHandler('showToastMessage', '요청인자가 유효하지 않습니다.');
        return;
      }
      setVerified(true);
      setStartTimer(false);
      setEngineCodeDisabled(true);
      setPhoneNumberDisabled(true);
    } catch (error) {
      await window.flutter_inappwebview.callHandler('goErrorPage', '');
    }
  };

  const handleTimeElapsed = async () => {
    setStartTimer(false);
    setOpenTimeElapsedAlert(true);
    await window.flutter_inappwebview.callHandler('showPhoneAuthTime', '인증번호 유효시간이 경과되었습니다. 휴대폰번호를 확인 후 다시 인증요청 해주세요.');
  };
  useEffect(() => {
    if (phNumber.length === 0) {
      setPhoneNumberDisabled(true);
    } else if (phNumber.length > 0 && !VALIDATION_MAP.ph_number.test(phNumber)) {
      setPhoneNumberDisabled(true);
      setHelperText(MESSAGES.ERROR_MESSAGE.VALID_PHONE_NUMBER);
    } else {
      setHelperText('');
      setPhoneNumberDisabled(false);
    }
  }, [phNumber]);

  useEffect(() => {
    if (engCode.length === 0) {
      setEngineCodeDisabled(true);
    } else if (engCode.length > 0 && engCode.length !== 6) {
      setEngineCodeDisabled(true);
      setHelperText(MESSAGES.ERROR_MESSAGE.VALID_ENGINE_NUMBER);
    } else {
      setHelperText('');
      setEngineCodeDisabled(false);
    }
  }, [engCode]);

  useEffect(() => {
    if (name.length === 0 || birthDate.length === 0 || gender.length === 0 || !verified) {
      setNextBtnDisabled(true);
    } else if (name.length !== 0 && !VALIDATION_MAP.koreanRegex.test(name)) {
      setHelperText(MESSAGES.ERROR_MESSAGE.ENTER_NAME);
      setNextBtnDisabled(true);
    } else if (birthDate.length === 0) {
      setHelperText(MESSAGES.ERROR_MESSAGE.ENTER_BIRTHDATE);
      setNextBtnDisabled(true);
    } else if (gender.length === 0) {
      setHelperText(MESSAGES.ERROR_MESSAGE.CHOOSE_GENDER);
      setNextBtnDisabled(true);
    } else if (!verified) {
      setNextBtnDisabled(true);
    } else {
      setHelperText('');
      setNextBtnDisabled(false);
    }
  }, [name, birthDate, gender, verified]);
  const handleNextBtn = async () => {
    let formattedDateStr = birthDate.replace(/\./g, '-');

    const body = {
      userBrdt: formattedDateStr,
      userNm: name,
      userCpTelno: phNumber,
      userGender: gender,
    };
    try {
      await accountApi.postAccountCertificationAuthAsync({ body });
      navigate('/more/profile/change-password/new-password');
    } catch (error) {
      console.log(error);
      // await window.flutter_inappwebview.callHandler('goErrorPage', '');
    }
  };
  const args = {
    placeholder: MESSAGES.PLACEHOLDER.INPUT,
    maxLength: 20,
  };
  const phoneNumberArgs = {
    placeholder: MESSAGES.PLACEHOLDER.PHONE_NUMBER,
    maxLength: 11,
  };
  const engineCodeArgs = {
    placeholder: MESSAGES.PLACEHOLDER.INPUT,
    maxLength: 6,
    width: '100px',
  };

  const nameField = {
    ...args,
    value: name,
    onChange: (event: ChangeEvent<HTMLInputElement>) => {
      setName(event.target.value);
    },
  };
  const phoneNumberField = {
    ...phoneNumberArgs,
    value: phNumber,
    onChange: (event: ChangeEvent<HTMLInputElement>) => setPhNumber(event.target.value),
  };
  const engineCodeField = {
    ...engineCodeArgs,
    value: engCode,
    onChange: (event: ChangeEvent<HTMLInputElement>) => setEngCode(event.target.value),
  };

  const props = {
    nameField,
    phoneNumberField,
    engineCodeField,
    handleGoBack,
    phoneNumberDisabled,
    engineCodeDisabled,
    handleNextBtn,
    nextBtnDisabled,
    handleCheckboxChange,
    gender,
    handleEngineCode,
    handlePhNumber,
    helperText,
    validationError,
    openDatepicker,
    birthDate,
    startTimer,
    handleTimeElapsed,
    verified,
    openTimeElapsedAlert,
  };

  return <UserIdentificationView {...props} />;
};

export default ProfileUserIdentification;
