import { AxiosResponse } from 'axios';
import { AxiosConfig, axiosErrorExceptionAsync } from '../axios.config';

export class TermsAPI extends AxiosConfig {
  private _prefix = '/v1';
  private _url = `${this._prefix}/terms`;

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(useBaseURL?: boolean, token?: string, device?: string) {
    super(useBaseURL, token, device);
  }

  /**
   * 13. 약관 정보 API - 약관 정보 조회
   * @see https://chc-app-api.huraydev.net/swagger-ui/index.html
   * @version 1.0.0
   */
  public getAsync = async (): Promise<AxiosResponse<any> | AxiosResponse<unknown, any>> => {
    try {
      return await this.instance.get<any>(`${this._url}`);
    } catch (error) {
      return axiosErrorExceptionAsync(error);
    }
  };
}
