import React, { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProfileNewPasswordView from './profile-new-password.view';
import { AccountAPI } from '../../../../../../apis/v1/account.api';
import MESSAGES from '../../../../../../constants/messages';
import VALIDATION_MAP from '../../../../../../constants/validationMap';
import useAuthentication from '../../../../../../store/useAuthentication';
import { EtcApi } from '../../../../../../apis/v1/etc.api';

const ProfileNewPassword = () => {
  const { authentication } = useAuthentication();
  const accountAPI = new AccountAPI(process.env.REACT_APP_ENV !== 'dev', authentication.accessToken, authentication.deviceId);
  const etcApi = new EtcApi(process.env.REACT_APP_ENV !== 'dev', authentication.accessToken, authentication.deviceId);
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(true);
  const [newPw, setNewPw] = useState('');
  const [confirmNewPw, setConfirmNewPw] = useState('');
  const [newHelperText, setNewPwHelperText] = useState('');
  const [confirmHelperText, setConfirmHelperText] = useState('');

  useEffect(() => {
    if (authentication.accessToken && authentication.deviceId) {
      const fetchData = async () => {
        try {
          await etcApi.getProfileAsync();
        } catch (error) {
          // await window.flutter_inappwebview.callHandler('goErrorPage', '');
        }
      };
      fetchData();
    }
  }, [authentication.accessToken, authentication.deviceId]);

  const handleNewPwChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNewPw(event.target.value);
  };

  const handleConfirmPwChange = (event: ChangeEvent<HTMLInputElement>) => {
    setConfirmNewPw(event.target.value);
  };

  const handleGoBack = () => {
    navigate('/more/profile');
  };

  const handlePwChangeButton = async () => {
    try {
      await accountAPI.patchPasswordAuthAsync({ body: { password: confirmNewPw } });
      navigate('/more/profile');
    } catch (error) {
      // await window.flutter_inappwebview.callHandler('goErrorPage', '');
    }
  };

  useEffect(() => {
    if (newPw.length === 0 || confirmNewPw.length === 0) {
      setDisabled(true);
    } else if (newPw.length !== 0 && !VALIDATION_MAP.pw.test(newPw)) {
      setNewPwHelperText(MESSAGES.ERROR_MESSAGE.VALID_PW);
      setDisabled(true);
    } else if (confirmNewPw?.length > 0 && newPw !== confirmNewPw) {
      setConfirmHelperText(MESSAGES.ERROR_MESSAGE.NEW_PW_NOT_MATCH);
      setDisabled(true);
    } else {
      setNewPwHelperText('');
      setConfirmHelperText('');
      setDisabled(false);
    }
  }, [newPw, confirmNewPw]);

  const passwordArgs = {
    maxLength: 13,
  };
  const newPwNumber = {
    ...passwordArgs,
    onChange: handleNewPwChange,
  };
  const confirmPwNumber = {
    ...passwordArgs,
    onChange: handleConfirmPwChange,
  };

  const props = {
    handleGoBack,
    handlePwChangeButton,
    disabled,
    newPwNumber,
    confirmPwNumber,
    newHelperText,
    confirmHelperText,
  };

  return <ProfileNewPasswordView {...props} />;
};

export default ProfileNewPassword;
