import React, { useState, useEffect } from 'react';
import Colors from '../../../types/colors';
import useDpi from '../../../hooks/useDpi';

interface TimerProps {
  shouldStart: boolean;
  onTimeElapsed: () => void;
}

const Timer = ({ shouldStart, onTimeElapsed }: TimerProps) => {
  const [seconds, setSeconds] = useState(180);
  const { secondaryFontSize } = useDpi();
  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (shouldStart && seconds > 0) {
      intervalId = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [shouldStart, seconds]);

  useEffect(() => {
    if (seconds === 0) {
      onTimeElapsed();
    }
  }, [seconds, onTimeElapsed]);

  const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  return <div style={{ color: `${Colors['Primary_100_basic']}`, fontSize: `${secondaryFontSize}px` }}>{formatTime(seconds)}</div>;
};
export default Timer;
