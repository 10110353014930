import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

export type AxiosRequest<P = any, Q = any, B = any, H = any> = {
  param?: P;
  type?: P;
  query?: Q;
  body?: B;
  header?: H;
};

export const axiosErrorExceptionAsync = (error: unknown): Promise<AxiosResponse<unknown, any>> => {
  const { response, request, message, config } = error as AxiosError;

  if (response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log(response.data);
    console.log(response.status);
    console.log(response.headers);
  } else if (request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', message);
  }

  console.log(config);

  return new Promise((resolve: (value: AxiosResponse<unknown, any>) => void): void => {
    resolve(response as AxiosResponse<unknown, any>);
  });
};

export abstract class AxiosConfig {
  protected instance: AxiosInstance;
  protected token?: string;
  protected device?: string;
  protected readonly baseURL: string;

  protected constructor(useBaseURL?: boolean, token?: string, device?: string) {
    this.baseURL = useBaseURL ? process.env.REACT_APP_API_URL || '' : '';
    this.instance = axios.create({
      baseURL: this.baseURL,
    });
    this.token = token;
    this.device = device;
    this.interceptor();
  }

  private interceptor = (): void => {
    this.instance.interceptors.request.use(this.request);
    this.instance.interceptors.response.use(this.response, this.errorResponse);
  };

  private request = (config: AxiosRequestConfig): any => {
    if (this.token) {
      config.headers!['Authorization'] = `Bearer ${this.token}`;
    }
    if (this.device) {
      config.headers!['DeviceUid'] = this.device;
    }
    config.headers!['Content-Type'] = 'application/json';
    config.headers!['Accept'] = '*/*';
    config.timeout = 30 * 1000;
    config.withCredentials = false;

    return config;
  };

  private response = (response: AxiosResponse): any => {
    return response;
  };

  private errorResponse = async (error: AxiosError<any, errType>) => {
    if (error.response?.data?.code.includes('ERR-401')) {
      return await window.flutter_inappwebview.callHandler('goRefresh', '');
    } else if (error.response?.data?.code?.includes('ERR_500')) {
      return await window.flutter_inappwebview.callHandler('goErrorPage', '');
    } else if (error.response?.data?.code.includes('ERR-406')) {
      return await window.flutter_inappwebview.callHandler('goRefresh', '');
    } else if (error.response?.data?.code === 'ERR_400_000') {
      return await window.flutter_inappwebview.callHandler('showToastMessage', '요청인자가 유효하지 않습니다.');
    } else if (error.response?.data?.code) {
      return await window.flutter_inappwebview.callHandler('showToastMessage', `${error.response?.data?.message}`);
    }
    return error;
  };
}

type errType = {
  code: string;
  etc: string;
  message: string;
  path: string;
  reason: string;
};
