import * as React from 'react';
import { useEffect, useState } from 'react';
import DashboardPageView from './dashboard-page.view';
import { DashboardAPI } from '../../../apis/v1/dashboard.api';
import useAuthentication from '../../../store/useAuthentication';
import { ReportAPI } from '../../../apis/v1/report.api';

const DashboardPage = () => {
  const { authentication } = useAuthentication();
  const dashboardApi = new DashboardAPI(process.env.REACT_APP_ENV !== 'dev', authentication.accessToken, authentication.deviceId);
  const reportApi = new ReportAPI(process.env.REACT_APP_ENV !== 'dev', authentication.accessToken, authentication.deviceId);

  const [dashboardPageData, setDashboardPageData] = useState<any>([]);
  const [reportPageData, setReportPageData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (authentication.accessToken?.length !== 0 && dashboardPageData.length !== undefined && reportPageData.length !== undefined) {
      setIsLoading(false);
    }
  }, [dashboardPageData, reportPageData, authentication.accessToken]);
  useEffect(() => {
    if (authentication.accessToken && authentication.deviceId) {
      const fetchData = async () => {
        try {
          const { data } = await reportApi.getSurveysAsync();
          setReportPageData(data);
        } catch (error) {
          // await window.flutter_inappwebview.callHandler('goErrorPage', '');
        }
      };
      fetchData();
    }
  }, [authentication.accessToken, authentication.deviceId]);
  useEffect(() => {
    if (authentication.accessToken && authentication.deviceId) {
      const fetchData = async () => {
        try {
          const { data }: any = await dashboardApi.getAsync();

          // Handle successful response
          setDashboardPageData(data);
        } catch (error) {
          // await window.flutter_inappwebview.callHandler('goErrorPage', '');
        }
      };
      fetchData();
    }
  }, [authentication.accessToken, authentication.deviceId]);
  const props = {
    dashboardPageData,
    reportPageData,
    isLoading,
  };
  return <DashboardPageView {...props} />;
};

export default DashboardPage;
