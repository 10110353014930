import React from 'react';
import More from '../../organisms/more/more';
import { css } from '@emotion/react';
import Colors from '../../../types/colors';

const MorePage = () => {
  const top = css`
    height: 50px;
    background-color: ${Colors.white_regular};
  `;

  return (
    <div>
      <div css={top} />
      <More />
    </div>
  );
};

export default MorePage;
