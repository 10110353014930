import { useState, useEffect } from 'react';

function useDpi() {
  const [primaryFontSize, setPrimaryFontSize] = useState(24);
  const [headerFontSize, setHeaderFontSize] = useState(20);
  const [iconFontSize, setIconFontSize] = useState(22.5);
  const [secondaryFontSize, setSecondaryFontSize] = useState(18);
  const [listTitle, setListTitle] = useState(16);
  const [subFontSize, setSubFontSize] = useState(14);
  const [miniFontSize, setMiniFontSize] = useState(12);
  const [imageSize, setImageSize] = useState(200);
  const [dashboardImageSize, setDashboardImageSize] = useState(60);
  const [alarmSize, setAlarmSize] = useState(33);
  const [iconSize, setIconSize] = useState(26.7);
  const [plusIcon, setPlusIcon] = useState(21.3);
  const [miniIcons, setMiniIcons] = useState(15);
  const [all, setAll] = useState(30);
  useEffect(() => {
    const updateDpi = () => {
      const dpi = window.devicePixelRatio * 160;
      // const pixelRatio = window.devicePixelRatio;
      // const scale = pixelRatio > 1 ? pixelRatio : 1;
      //
      // let all = 36;
      //
      // let primaryFontSize = 24;
      // let headerFontSize = 20;
      // let iconFontSize = 20;
      // let secondaryFontSize = 16;
      // let subFontSize = 14;
      // let miniFontSize = 12;
      // let imageSize = 60;
      // let alarmSize = 34;
      // let iconSize = 27;
      // let miniIcons = 15;
      ///////////////////////////////
      // let primaryFontSize = primaryFontSizeSp * (dpi / 160) * scale;
      // let headerFontSize = headerFontSizeSp * (dpi / 160) * scale;
      // let iconFontSize = iconFontSizeSp * (dpi / 160) * scale;
      // let secondaryFontSize = secondaryFontSizeSp * (dpi / 160) * scale;
      // let subFontSize = subFontSizeSp * (dpi / 160) * scale;
      // let miniFontSize = miniFontSizeSp * (dpi / 160) * scale;
      // let imageSize = (imageSizeSp * dpi) / 160;
      // let miniIcons = (miniIconsSp * dpi) / 160;
      // let iconSize = (iconSizeSp * dpi) / 160;
      // if (dpi < 160) {
      //   primaryFontSize = 35;
      //   headerFontSize = 32;
      //   iconFontSize = 30;
      //   secondaryFontSize = 25;
      //   subFontSize = 22;
      //   miniFontSize = 20;
      //   imageSize = 160;
      //   alarmSize = 60;
      //   iconSize = 30;
      //   miniIcons = 30;
      // } else if (dpi >= 160 && dpi < 213) {
      //   primaryFontSize = 32;
      //   headerFontSize = 30;
      //   iconFontSize = 27;
      //   secondaryFontSize = 22;
      //   subFontSize = 20;
      //   miniFontSize = 18;
      //   imageSize = 150;
      //   alarmSize = 50;
      //   iconSize = 27;
      //   miniIcons = 27;
      // } else if (dpi >= 213 && dpi < 240) {
      //   primaryFontSize = 28;
      //   headerFontSize = 25;
      //   iconFontSize = 24;
      //   secondaryFontSize = 18;
      //   subFontSize = 17;
      //   miniFontSize = 15;
      //   imageSize = 140;
      //   alarmSize = 43;
      //   iconSize = 23;
      //   miniIcons = 23;
      // } else if (dpi >= 240 && dpi < 320) {
      //   primaryFontSize = 26;
      //   headerFontSize = 23;
      //   iconFontSize = 21;
      //   secondaryFontSize = 17;
      //   subFontSize = 16;
      //   miniFontSize = 13;
      //   imageSize = 130;
      //   alarmSize = 45;
      //   iconSize = 22;
      //   miniIcons = 22;
      // } else if (dpi >= 320 && dpi < 480) {
      //   primaryFontSize = 24;
      //   headerFontSize = 23;
      //   iconFontSize = 18;
      //   secondaryFontSize = 18;
      //   subFontSize = 15;
      //   miniFontSize = 13;
      //   imageSize = 120;
      //   alarmSize = 40;
      //   iconSize = 22;
      //   miniIcons = 22;
      // } else if (dpi >= 480 && dpi < 640) {
      //   primaryFontSize = 21;
      //   headerFontSize = 20;
      //   iconFontSize = 15;
      //   secondaryFontSize = 15;
      //   subFontSize = 12;
      //   miniFontSize = 10;
      //   imageSize = 110;
      //   alarmSize = 35;
      //   iconSize = 18;
      //   miniIcons = 18;
      // } else if (dpi >= 640) {
      //   primaryFontSize = 18;
      //   headerFontSize = 16;
      //   iconFontSize = 12;
      //   secondaryFontSize = 12;
      //   subFontSize = 10;
      //   miniFontSize = 8;
      //   imageSize = 110;
      //   alarmSize = 30;
      //   iconSize = 15;
      //   miniIcons = 15;
      // }
      // setPrimaryFontSize(primaryFontSize);
      // setHeaderFontSize(headerFontSize);
      // setIconFontSize(iconFontSize);
      // setSubFontSize(subFontSize);
      // setSecondaryFontSize(secondaryFontSize);
      // setMiniFontSize(miniFontSize);
      // setImageSize(imageSize);
      // setAlarmSize(alarmSize);
      // setIconSize(iconSize);
      // setMiniIcons(miniIcons);
    };

    updateDpi();

    window.addEventListener('resize', updateDpi);

    return () => window.removeEventListener('resize', updateDpi);
  }, [window.devicePixelRatio]);
  return {
    primaryFontSize,
    headerFontSize,
    iconFontSize,
    secondaryFontSize,
    subFontSize,
    miniFontSize,
    imageSize,
    iconSize,
    miniIcons,
    alarmSize,
    all,
    listTitle,
    plusIcon,
    dashboardImageSize,
  };
}
export default useDpi;
