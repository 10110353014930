import React, { forwardRef } from 'react';
import { css } from '@emotion/react';
import { SerializedStyles } from '@emotion/serialize';
import Colors from '../../../types/colors';
import useViewportHeight from '../../../hooks/useViewportHeight';

type AttrType = 'value' | 'onChange' | 'placeholder' | 'maxLength' | 'disabled';

export type TextareaViewProps = {
  cssStyles?: SerializedStyles;
} & Pick<React.ComponentProps<'textarea'>, AttrType>;

const TextareaView = forwardRef<HTMLTextAreaElement, TextareaViewProps>((props, ref) => {
  const viewportHeight = useViewportHeight();
  const styles = {
    box: css`
      position: relative;
    `,
    textarea: css`
      resize: none;
      box-sizing: border-box;
      width: 100%;
      height: ${viewportHeight - 190 + 'px'};
      outline: 0;
      border: 0;
      line-height: 164%;
      color: ${Colors['Black_basic']};
      font-family: var(--normal);
      letter-spacing: -0.03em;
      font-size: 14px;
      padding: 12px 0;
      overflow-y: hidden;
      background-color: inherit;

      &::-webkit-scrollbar {
        display: none;
      }

      &::placeholder {
        color: ${Colors.Grey_03_regular};
      }

      &:focus + span {
        height: 2px;
        background-color: ${Colors['Black_basic']};
      }
    `,
    line: css`
      display: block;
      width: 100%;
      height: 1px;
      background-color: ${Colors['Grey_01_bg']};
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    `,
  };
  return (
    <>
      <div css={styles.box}>
        <textarea ref={ref} rows={1} css={styles.textarea} {...props} />
        {/*<span css={styles.line} />*/}
      </div>
    </>
  );
});

export default TextareaView;
