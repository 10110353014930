import React, { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProfileChangePasswordView from './profile-change-password.view';
import VALIDATION_MAP from '../../../../../constants/validationMap';
import MESSAGES from '../../../../../constants/messages';
import { AccountAPI } from '../../../../../apis/v1/account.api';
import useAuthentication from '../../../../../store/useAuthentication';

const ProfileChangePassword = () => {
  const { authentication } = useAuthentication();
  const accountAPI = new AccountAPI(process.env.REACT_APP_ENV !== 'dev', authentication.accessToken, authentication.deviceId);
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(true);
  const [curPw, setCurPw] = useState('');
  const [newPw, setNewPw] = useState('');
  const [confirmNewPw, setConfirmNewPw] = useState('');
  const [curPwHelperText, setCurPwHelperText] = useState('');
  const [newHelperText, setNewPwHelperText] = useState('');
  const [confirmHelperText, setConfirmHelperText] = useState('');

  const handleCurPwChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCurPw(event.target.value);
  };

  const handleNewPwChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNewPw(event.target.value);
  };

  const handleConfirmPwChange = (event: ChangeEvent<HTMLInputElement>) => {
    setConfirmNewPw(event.target.value);
  };

  const handleSearchPwBtn = () => {
    navigate('/more/profile/change-password/user-identification');
  };

  const handleGoBack = () => {
    navigate('/more/profile');
  };

  const handlePwChangeButton = async () => {
    try {
      const { data } = await accountAPI.postAccountPasswordCertificationAuthAsync({ body: { password: curPw } });
      if (data?.code !== 'ERR-400-006') {
        await accountAPI.patchPasswordAuthAsync({ body: { password: confirmNewPw } });
        navigate('/more/profile');
        return;
      } else {
        setCurPwHelperText(`${data?.message}`);
      }
    } catch (error) {
      // await window.flutter_inappwebview.callHandler('goErrorPage', '');
    }
  };

  useEffect(() => {
    if (newPw.length === 0 || confirmNewPw.length === 0 || curPw.length === 0) {
      setDisabled(true);
    } else if (newPw.length !== 0 && !VALIDATION_MAP.pw.test(newPw)) {
      setNewPwHelperText(MESSAGES.ERROR_MESSAGE.VALID_PW);
      setDisabled(true);
    } else if (confirmNewPw?.length > 0 && newPw !== confirmNewPw) {
      setConfirmHelperText(MESSAGES.ERROR_MESSAGE.NEW_PW_NOT_MATCH);
      setDisabled(true);
    } else {
      setNewPwHelperText('');
      setConfirmHelperText('');
      setDisabled(false);
    }
  }, [curPw, newPw, confirmNewPw]);

  const passwordArgs = {
    maxLength: 13,
  };
  const curPwNumber = {
    ...passwordArgs,
    onChange: handleCurPwChange,
  };
  const newPwNumber = {
    ...passwordArgs,
    onChange: handleNewPwChange,
  };
  const confirmPwNumber = {
    ...passwordArgs,
    onChange: handleConfirmPwChange,
  };

  const props = {
    handleSearchPwBtn,
    handleGoBack,
    handlePwChangeButton,
    disabled,
    curPwNumber,
    newPwNumber,
    confirmPwNumber,
    curPwHelperText,
    newHelperText,
    confirmHelperText,
  };

  return <ProfileChangePasswordView {...props} />;
};

export default ProfileChangePassword;
