import { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { css } from '@emotion/react';
import Colors from '../../../types/colors';
import MESSAGES from '../../../constants/messages';
import { ImageEmptyGraph } from '../../../assets/images/svg';
import useDpi from '../../../hooks/useDpi';

interface BarChartProps {
  BarData?: any;
}

function BarChart({ BarData }: BarChartProps) {
  const [maxValue, setMaxValue] = useState<any>(BarData?.maxValue);
  const { subFontSize, miniFontSize } = useDpi();
  const maxVal = BarData?.maxValue;
  const uniqueNthYears = new Set(BarData?.graph?.map((item: { nthYear: number }) => item.nthYear));
  const defaultLabels = Array.from({ length: uniqueNthYears.size }, (_, index) => {
    const graphData = BarData?.graph?.find((d: { nthYear: number }) => d.nthYear === index + 1);
    if (graphData) {
      return `${graphData.nthYear}차`;
    }
    return `${index + 1}차`;
  });

  const defaultData = Array.from({ length: uniqueNthYears.size + 1 }, (_, index) => {
    const graphData = BarData?.graph[index];
    if (graphData) {
      const value = parseFloat(graphData?.value?.toFixed(1));
      return value !== 0 ? value : null;
    }
    return '';
  });
  const isDataEmpty = defaultData.every((value) => value === '');
  defaultLabels.push('(차수)');

  const chartRef = useRef(null);

  Chart.defaults.font.size = subFontSize;

  const separatedData = BarData?.graph?.reduce((acc: { [x: string]: any[] }, current: { nthYear: any }) => {
    const year = current.nthYear;
    if (!acc[year]) {
      acc[year] = [];
    }
    acc[year].push(current);
    return acc;
  }, {});
  const dataArray = Object.values(separatedData).map((yearArray: any) => yearArray.map((obj: { value: any }) => obj.value));
  const colorArray = Object.values(separatedData).map((yearArray: any) => yearArray.map((obj: { color: any }) => obj.color));
  const itemNameArray = Object.values(separatedData).map((yearArray: any) => yearArray.map((obj: { itemName: any }) => obj.itemName));

  const labelDatas = itemNameArray.find((item) => {
    return item[0] !== '';
  });
  const data1: any = [];
  const data2: any = [];
  const data3: any = [];

  const color1: any = [];
  const color2: any = [];
  const color3: any = [];
  dataArray.forEach((arr, i) => {
    if (arr.length === 1) {
      data1.push(arr[0]);
      data2.push('');
      data3.push('');
      color1.push(colorArray[i][0]);
      color2.push('');
      color3.push('');
    } else if (arr.length === 2) {
      data1.push(arr[0]);
      data2.push(arr[1]);
      data3.push('');
      color1.push(colorArray[i][0]);
      color2.push(colorArray[i][1]);
      color3.push('');
    } else if (arr.length === 3) {
      data1.push(arr[0]);
      data2.push(arr[1]);
      data3.push(arr[2]);
      color1.push(colorArray[i][0]);
      color2.push(colorArray[i][1]);
      color3.push(colorArray[i][2]);
    }
  });

  while (data1.length < 5) {
    data1.push('');
  }
  while (data2.length < 5) {
    data2.push('');
  }
  while (data3.length < 5) {
    data3.push('');
  }

  const hasData2 = data2.some((value: any) => typeof value === 'number');
  const hasData3 = data3.some((value: any) => typeof value === 'number');

  const barData = [
    {
      label: `${(labelDatas && labelDatas[0]) ?? ''} `,
      data: data1.map((value: any) => value),
      backgroundColor: color1,
      barPercentage: 0.9,
      categoryPercentage: 0.3,
      borderRadius: 10,
    },
    ...(hasData2
      ? [
          {
            label: `${(labelDatas && labelDatas[1]) ?? ''} `,
            data: data2.map((value: any) => (value !== 0 ? value : null)),
            backgroundColor: color2,
            barPercentage: 0.9,
            categoryPercentage: 0.3,
            borderRadius: 10,
          },
        ]
      : []),
    ...(hasData3
      ? [
          {
            label: `${(labelDatas && labelDatas[2]) ?? ''} `,
            data: data3.map((value: any) => (value !== 0 ? value : null)),
            backgroundColor: color3,
            barPercentage: 0.9,
            categoryPercentage: 0.3,
            borderRadius: 10,
          },
        ]
      : []),
  ];
  useEffect(() => {
    const data = {
      labels: defaultLabels,
      datasets: barData,
    };
    const horizontalDottedLine = {
      id: 'horizontalDottedLine',
      beforeDatasetsDraw(chart: Chart, args: { cancelable: true }, options: any): boolean | void {
        const {
          ctx,
          chartArea: { top, right, bottom, left, width, height },
          scales: { x, y },
        } = chart;
        const meta = chart.getDatasetMeta(0);

        ctx.save();

        //draw line
        ctx.strokeStyle = 'black';
        ctx.lineWidth = 0.2;
        ctx.setLineDash([1.5, 3]);

        const isValueZero = BarData?.graph?.length === 1 && BarData?.graph[0].value === 0;

        if (!isDataEmpty && !isValueZero) {
          // const maxValY = y.getPixelForValue(maxVal);
          const redMaxLineY = y.getPixelForValue(BarData?.colorLineInfo?.redMaxLine);
          const orangeMaxLineY = y.getPixelForValue(BarData?.colorLineInfo?.orangeMaxLine);
          const greenMaxLineY = y.getPixelForValue(BarData?.colorLineInfo?.greenMaxLine);
          const red = BarData?.colorLineInfo?.redMaxLine;
          const orange = BarData?.colorLineInfo?.orangeMaxLine;
          const green = BarData?.colorLineInfo?.greenMaxLine;
          ctx.strokeRect(left, greenMaxLineY, width - 24, 0);
          ctx.strokeRect(left, orangeMaxLineY, width - 24, 0);
          ctx.strokeRect(left, redMaxLineY, width - 24, 0);

          ctx.fillStyle = 'black';
          ctx.textAlign = 'right';
          ctx.font = `${11}px -apple-system,BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif`;

          ctx.textAlign = 'start';
          // Calculate the vertical position for each label
          const isRedGreater = red > green;
          const greenLabelY = isRedGreater ? (greenMaxLineY + bottom) / 2 : (greenMaxLineY + orangeMaxLineY) / 2;
          const orangeLabelY = isRedGreater ? (orangeMaxLineY + greenMaxLineY) / 2 : (orangeMaxLineY + redMaxLineY) / 2;
          const redLabelY = isRedGreater ? (redMaxLineY + orangeMaxLineY) / 2 : (redMaxLineY + bottom) / 2;

          // Draw labels on the left side with colors
          ctx.fillStyle = 'green'; // Green color
          ctx.textAlign = 'end';
          ctx.fillText(`좋음`, left - 10, greenLabelY);

          ctx.fillStyle = 'orange'; // Orange color
          ctx.fillText(`중간`, left - 10, orangeLabelY);

          ctx.fillStyle = 'red'; // Red color
          ctx.fillText(`나쁨`, left - 10, redLabelY);

          ctx.textAlign = 'start';
          ctx.fillStyle = 'black'; // Red color

          ctx.fillText(`${green}`, right - 20, greenMaxLineY + 5);
          ctx.fillText(`${orange}`, right - 20, orangeMaxLineY + 5);
          ctx.fillText(`${red}`, right - 20, redMaxLineY + 5);
        }
        ctx.restore();
      },
    };

    // no data plugin
    const noData = {
      id: 'noData',
      afterDatasetsDraw: (chart: { ctx: any; data: any; getDatasetMeta?: any; chartArea: { top: any; bottom: any; left: any; right: any; width: any; height: any } }, args: any, plugins: any) => {
        const {
          ctx,
          data,
          chartArea: { top, bottom, left, right, width, height },
        } = chart;

        ctx.save();

        chart.data.datasets.forEach((dataset: { data: { [x: string]: any } }) => {
          const meta = chart.getDatasetMeta(0);
          const bars = meta.data;

          // Loop through each bar
          bars.forEach((bar: { x: any }, index: string | number) => {
            const xPosition = bar.x;
            // const yPosition = y.getPixelForValue(datasets[0].data[index]) - 20;
            if (isDataEmpty) {
              ctx.font = `${subFontSize}px`;
              ctx.textAlign = 'center';
              ctx.fillStyle = `${Colors['Grey_05_subtext']}`;
              const img = new Image();
              img.src = ImageEmptyGraph;
              img.onload = () => {
                const imgWidth = 45;
                const imgHeight = 45;
                const imgX = left + width / 2 - imgWidth / 2; // center horizontally
                const imgY = top + height / 3; // 1/3 of the way down vertically
                ctx.drawImage(img, imgX, imgY, imgWidth, imgHeight);

                const textTopY = imgY + imgHeight + 15; // 10px padding
                const textBottomY = textTopY + 16; // 16px line height
                const textX = left + width / 2; // center horizontally
                ctx.fillText(`${MESSAGES.INFORMATION.EMPTY_GRAPH_DATA.TOP}`, textX, textTopY, 150);
                ctx.fillText(`${MESSAGES.INFORMATION.EMPTY_GRAPH_DATA.TOP}`, textX, textBottomY, 150);
              };
            }
          });
        });
      },
    };

    const max = maxVal * 1.2;

    // config
    const config = {
      type: 'bar',
      data,
      options: {
        scales: {
          y: {
            beginAtZero: true,
            max: max,
            gridLines: {
              display: false,
            },
            afterTickToLabelConversion: (ctx: { fillText: any; ticks: { value: number; label: string; color: string }[] }) => {
              ctx.ticks = [];

              if (isDataEmpty) {
                ctx.ticks = [];
              }
            },

            grid: {
              display: false,
              drawTicks: false,
              drawBorder: false,
            },
          },
          x: {
            beginAtZero: true,
            ticks: {
              display: true,
              color: (context: any, index: any) => {
                const tickColor: string[] = [];
                context.chart.data.datasets[0].data.forEach((datapoint: string, index: any) => {
                  if (datapoint === '') {
                    tickColor.push('#aaaaac');
                  } else {
                    tickColor.push('black');
                  }
                });
                return tickColor;
              },
              padding: 8,
            },

            grid: {
              display: false,
              drawBorder: false,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            yAlign: 'bottom',
          },
          datalabels: {
            anchor: 'end',
            align: 'top',
            font: {
              size: `${subFontSize}`,
            },
          },
        },

        layout: {
          padding: {
            right: 20,
            left: 33,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        chartArea: {
          height: '100%',
          width: '100%',
        },
      },

      plugins: [ChartDataLabels, horizontalDottedLine, noData],
    };

    // render init block
    // @ts-ignore
    const myChart = new Chart(chartRef.current, config);

    // Instantly assign Chart.js version
    const chartVersion = document.getElementById('chartVersion');
    // @ts-ignore
    chartVersion.innerText = Chart.version;

    return () => {
      // @ts-ignore
      // window.removeEventListener('resize', subFontSize);
      myChart.destroy();
    };
  }, [BarData]);

  const wrapper = css`
    //width: 360px;
    //height: 226px;
    position: relative;
    margin-top: 10px;
    font-size: ${subFontSize}px;
  `;

  const barChartWrapper = css`
    height: 226px;
    width: 360px;
    overflow-x: scroll;
    @media only screen and (max-width: 1280px) {
      width: ${uniqueNthYears.size > 5 ? 1070 : 1100}px;
      height: 440px;
    }

    @media only screen and (max-width: 1024px) {
      width: ${uniqueNthYears.size > 5 ? 980 : 1000}px;
      height: 390px;
    }
    @media only screen and (max-width: 912px) {
      width: ${uniqueNthYears.size > 5 ? 844 : 880}px;
      height: 390px;
    }
    @media only screen and (max-width: 820px) {
      width: ${uniqueNthYears.size > 5 ? 750 : 780}px;
      height: 360px;
    }

    @media only screen and (max-width: 768px) {
      width: ${uniqueNthYears.size > 5 ? 720 : 750}px;
      height: 330px;
    }
    @media only screen and (max-width: 540px) {
      width: ${uniqueNthYears.size > 5 ? 470 : 500}px;
      height: 300px;
    }
    @media only screen and (max-width: 414px) {
      width: ${uniqueNthYears.size > 5 ? 342 : 374}px;
      height: 250px;
    }
    @media only screen and (max-width: 412px) {
      width: ${uniqueNthYears.size > 5 ? 340 : 370}px;
      height: 250px;
    }
    @media only screen and (max-width: 393px) {
      width: ${uniqueNthYears.size > 5 ? 325 : 350}px;
      height: 220px;
    }
    @media only screen and (max-width: 375px) {
      width: ${uniqueNthYears.size > 5 ? 328 : 345}px;
      height: 210px;
    }
    @media only screen and (max-width: 360px) {
      width: ${uniqueNthYears.size > 5 ? 322 : 340}px;
      height: 210px;
    }

    @media only screen and (max-width: 280px) {
      width: ${uniqueNthYears.size > 5 ? 220 : 240}px;
      height: 200px;
    }
  `;

  const barChart = css`
    width: ${uniqueNthYears.size > 5 ? 300 : 340}px;
    height: inherit;

    @media only screen and (max-width: 1280px) {
      width: ${defaultLabels.length > 5 ? 1100 + (defaultLabels.length - 5) * 50 : 1100}px;
      height: 440px;
    }

    @media only screen and (max-width: 1024px) {
      width: ${defaultLabels.length > 5 ? 1000 + (defaultLabels.length - 5) * 50 : 1000}px;
      height: 390px;
    }
    @media only screen and (max-width: 912px) {
      width: ${defaultLabels.length > 5 ? 880 + (defaultLabels.length - 5) * 50 : 800}px;
      height: 390px;
    }
    @media only screen and (max-width: 820px) {
      width: ${defaultLabels.length > 5 ? 780 + (defaultLabels.length - 5) * 50 : 780}px;
      height: 360px;
    }

    @media only screen and (max-width: 768px) {
      width: ${defaultLabels.length > 5 ? 750 + (defaultLabels.length - 5) * 50 : 750}px;
      height: 330px;
    }
    @media only screen and (max-width: 540px) {
      width: ${defaultLabels.length > 5 ? 500 + (defaultLabels.length - 5) * 50 : 500}px;
      height: 300px;
    }
    @media only screen and (max-width: 414px) {
      width: ${defaultLabels.length > 5 ? 374 + (defaultLabels.length - 5) * 50 : 374}px;
      height: 250px;
    }
    @media only screen and (max-width: 412px) {
      width: ${defaultLabels.length > 5 ? 370 + (defaultLabels.length - 5) * 50 : 370}px;
      height: 250px;
    }
    @media only screen and (max-width: 393px) {
      width: ${defaultLabels.length > 5 ? 345 + (defaultLabels.length - 5) * 50 : 345}px;
      height: 220px;
    }
    @media only screen and (max-width: 375px) {
      width: ${defaultLabels.length > 5 ? 345 + (defaultLabels.length - 5) * 50 : 345}px;
      height: 210px;
    }
    @media only screen and (max-width: 360px) {
      width: ${defaultLabels.length > 5 ? 340 + (defaultLabels.length - 5) * 50 : 340}px;
      height: 210px;
    }

    @media only screen and (max-width: 280px) {
      width: ${defaultLabels.length > 5 ? 260 + (defaultLabels.length - 5) * 50 : 260}px;
      height: 200px;
    }
  `;
  const ySales = css`
    position: relative;
    left: 12px;
    top: 0px;
    color: #aaaaac;
  `;

  return (
    <div css={wrapper}>
      <div css={ySales}>(점수)</div>
      <span id="chartVersion" style={{ display: 'none' }}></span>
      <div css={barChartWrapper}>
        <div css={barChart}>
          <canvas ref={chartRef}></canvas>
        </div>
      </div>
    </div>
  );
}

export default BarChart;
