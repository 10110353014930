import React, { ReactNode } from 'react';
import { css } from '@emotion/react';

import { SerializedStyles } from '@emotion/serialize';
import Colors from '../../../types/colors';

type AttrType = 'onChange' | 'value' | 'placeholder' | 'disabled' | 'type' | 'maxLength' | 'name';
export type TextFieldViewProps = {
  actionComponent?: ReactNode;
  css?: SerializedStyles;
  boxStyle?: SerializedStyles;
  placeholderLeft?: boolean;
} & Pick<React.ComponentProps<'input'>, AttrType>;

const TextFieldView = ({ actionComponent, placeholderLeft, type = 'text', ...rest }: TextFieldViewProps): JSX.Element => {
  const styles = {
    box: css`
      position: relative;
      //height: auto;
      ${{ ...rest.boxStyle }}
    `,
    input: css`
      min-width: ${actionComponent ? 'calc(100% - 36px)' : ''};
      letter-spacing: -0.03em;
      font-family: var(--normal);
      color: ${Colors['Black_basic']};
      text-align: ${placeholderLeft ? 'left' : 'right'};
      font-size: 18px;
      line-height: 156%;
      border: 0;
      right: 0;
      ${{ ...rest.css }};

      &:disabled {
        background-color: inherit;
      }

      &::placeholder {
        color: ${Colors['Grey_04_inactive']};
        right: 0;
        left: ${placeholderLeft && 0} !important;
      }
    `,
    border: css`
      display: block;
      width: fit-content;
      height: 1px;
      background-color: ${Colors['Grey_02_line']};
      position: absolute;
      bottom: 0;
      left: 0;
    `,
    icon: css`
      position: absolute;
      right: 0;
      bottom: 8px;
      z-index: 100;
    `,
  };

  return (
    <>
      <div css={styles.box}>
        <input type={type} {...rest} css={styles.input} id={type === 'password' ? 'passwordField' : ''} autoComplete={'off'} />
        <span css={styles.border} />
        <div css={styles.icon}>{actionComponent && actionComponent}</div>
      </div>
    </>
  );
};

export default TextFieldView;
