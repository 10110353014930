import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MoreTermsView from './more-terms.view';
import Icon from '../../../atoms/foundation/icon.view';
import { ArrowNext } from '../../../../assets/images/svg';
import MESSAGES from '../../../../constants/messages';

export interface ListItem {
  title: string;
  value: any;
  pathname: string;
}

const listItem: ListItem[] = [
  { title: MESSAGES.ROW_TITLES.TERMS_AND_CONDITIONS.TERMS_OF_SERVICE, value: <Icon src={ArrowNext} width={'15px'} height={'15px'} />, pathname: 'terms-of-services' },
  { title: MESSAGES.ROW_TITLES.TERMS_AND_CONDITIONS.PRIVACY_POLICY, value: <Icon src={ArrowNext} width={'15px'} height={'15px'} />, pathname: 'privacy-and-policy' },
  { title: MESSAGES.ROW_TITLES.TERMS_AND_CONDITIONS.SENSITIVE_INFO, value: <Icon src={ArrowNext} width={'15px'} height={'15px'} />, pathname: 'sensitive-info' },
];
const MoreTerms = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const props = {
    handleGoBack,
    listItem,
    location,
  };

  return <MoreTermsView {...props} />;
};

export default MoreTerms;
