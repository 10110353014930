import React from 'react';
import { css } from '@emotion/react';
import ReportPartBodyView from '../../../molecules/report/reportParts/report-part-body.view';
import HeadView from '../../../molecules/common/head.view';
import BackArrow from '../../../../assets/images/svg/icons_arrow_back.svg';
import Icon from '../../../atoms/foundation/icon.view';
import ReportPartsNavbar from '../../../molecules/report/reportParts/report-part-navbar';
import useDpi from '../../../../hooks/useDpi';
import Colors from '../../../../types/colors';

interface ReportInformationViewProps {
  reportDetailData: any;
  handleBack: () => void;
  handleButtonClick: (button: string) => void;
  activeButton: string;
  header: string | undefined;
  buttonData: any;
  isLoading: boolean;
}

const ReportInformationView = ({ buttonData, reportDetailData, handleBack, handleButtonClick, activeButton, header, isLoading }: ReportInformationViewProps) => {
  const { miniIcons } = useDpi();
  const top = css`
    height: 50px;
    background-color: ${Colors.white_regular};
  `;
  const wrapper = css`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: ${Colors.Grey_01_bg};
  `;

  const props = {
    head: {
      handleClick: handleBack,
      header: header + ' 리포트',
    },
    navbar: {
      buttonData,
      reportDetailData,
      activeButton,
      handleButtonClick,
    },
    body: {
      buttonData,
      reportDetailData,
      activeButton,
      isLoading,
    },
  };

  return (
    <div css={wrapper}>
      <div css={top} />
      <HeadView {...props.head} icon={<Icon src={BackArrow} width={`${miniIcons}px`} height={`${miniIcons}px`} />} />
      <ReportPartsNavbar {...props.navbar} />
      <ReportPartBodyView {...props.body} />
    </div>
  );
};

export default ReportInformationView;
