import React from 'react';
import { css } from '@emotion/react';
import { CheckOff60, CheckOnPrimary } from '../../../assets/images/svg';
import Icon from '../foundation/icon.view';
import useDpi from '../../../hooks/useDpi';
import TypographyView from '../foundation/typography.view';
export type Gender = 'M' | 'F' | string;
interface CheckboxProps {
  gender: 'M' | 'F' | string;
  checked: boolean;
  onChange: (gender: Gender) => void;
  text: string;
}
const CheckboxView = ({ gender, checked, onChange, text }: CheckboxProps) => {
  const { iconFontSize } = useDpi();
  const checkboxLabel = css`
    display: flex;
    align-items: center;
    gap: 5px;
  `;
  const checkboxInput = css`
    display: none;
  `;

  return (
    <label css={checkboxLabel}>
      <input type="checkbox" css={checkboxInput} checked={checked} onChange={() => onChange(gender)} />
      <Icon src={checked ? CheckOnPrimary : CheckOff60} width={`${iconFontSize}px`} height={`${iconFontSize}px`} />
      <TypographyView fontSize={14} children={text} />
    </label>
  );
};

export default CheckboxView;
