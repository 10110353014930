import {
  GraphAnxietyWorry,
  GraphEmotionalSupport,
  GraphFamilyActivities,
  GraphFatigue,
  GraphFunctionPhysical,
  GraphFunctionPositive,
  GraphFunctionSocial,
  GraphPeerRelations,
  GraphPhysicalStress,
  GraphSchool,
  GraphSleep,
  GraphSocialBehavior,
  GraphSocialRole,
  GraphSocialSupport,
} from './svg';
interface IconMap {
  [key: string]: string;
}
let iconMap: IconMap = {
  PAR_B_1: GraphSleep,
  PAR_B_2: GraphFatigue,
  PAR_B_3: GraphFunctionPhysical,
  PAR_B_4: GraphSleep,
  PAR_B_5: GraphFatigue,
  PAR_B_6: GraphFunctionPhysical,
  PAR_M_1: GraphAnxietyWorry,
  PAR_M_2: GraphAnxietyWorry,
  PAR_M_3: GraphFunctionPositive,
  PAR_R_1: GraphSchool,
  PAR_S_1: GraphFamilyActivities,
  PAR_S_2: GraphEmotionalSupport,
  PAR_S_3: GraphSocialSupport,
  PAR_S_4: GraphFunctionSocial,
  PAR_S_5: GraphSocialBehavior,
  PAR_S_6: GraphFamilyActivities,
  PAR_S_7: GraphSocialRole,
  PAR_S_8: GraphFamilyActivities,
  PAR_S_9: GraphFamilyActivities,
  PAR_S_10: GraphFamilyActivities,
  CHI_B_1: GraphFunctionPhysical,
  CHI_M_1: GraphAnxietyWorry,
  CHI_R_2: GraphSchool,
  CHI_S_1: GraphPeerRelations,
};

export default iconMap;
