import { AxiosResponse } from 'axios';
import { AxiosConfig, axiosErrorExceptionAsync, AxiosRequest } from '../axios.config';

export type VocParamRequest = {
  id: number;
};

export type GetVocListResponse = {
  dataList: {
    isAnswered: string;
    regAt: string;
    vocId: number;
    vocTitle: string;
  }[];
};

export type PostVocRequest = {
  vocTitle: string;
  vocContent: string;
  vocAt: string;
};

export type PostVocResponse = {
  message: string;
  vocId: number;
};

export type GetVocDetailsResponse = {
  managerAnswer: string;
  managerAnswerAt: string;
  managerName: string;
  vocAt: string;
  vocContent: string;
  vocTitle: string;
};

export type DeleteVocResponse = {
  message: string;
};

export type GetProfileResponse = {
  certificationCode: string;
  familyBirthDate: string;
  familyGender: string;
  familyName: string;
  familyType: string;
  loginBirthDate: string;
  loginGender: string;
  loginId: string;
  loginName: string;
  loginType: string;
  managerCpTelno: string;
  managerTelno: string;
};

export type GetMessagesListResponse = {
  dataList: any[];
};

export class EtcApi extends AxiosConfig {
  private _prefix = '/v1';
  private _url = `${this._prefix}/etc`;

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(useBaseURL?: boolean, token?: string, device?: string) {
    super(useBaseURL, token, device);
  }

  /**
   * 08. 더보기 API - 질문&답변 목록 조회
   * @see https://chc-app-api.huraydev.net/swagger-ui/index.html
   * @version 1.0.0
   */
  public getVocListAsync = async (): Promise<AxiosResponse<GetVocListResponse> | AxiosResponse<unknown, any>> => {
    try {
      return await this.instance.get<GetVocListResponse>(`${this._url}/voc`);
    } catch (error) {
      return axiosErrorExceptionAsync(error);
    }
  };

  /**
   * 08. 더보기 API - 질문&답변 등록
   * @see https://chc-app-api.huraydev.net/swagger-ui/index.html
   * @version 1.0.0
   */
  public postVocAsync = async ({ body }: AxiosRequest<any, any, PostVocRequest>): Promise<AxiosResponse<PostVocResponse> | AxiosResponse<unknown, any>> => {
    try {
      return await this.instance.post<PostVocResponse>(`${this._url}/voc`, {
        ...body,
      });
    } catch (error) {
      return axiosErrorExceptionAsync(error);
    }
  };

  /**
   * 08. 더보기 API - 질문&답변 상세 조회
   * @see https://chc-app-api.huraydev.net/swagger-ui/index.html
   * @version 1.0.0
   */
  public getVocDetailsAsync = async ({ param }: AxiosRequest<VocParamRequest>): Promise<AxiosResponse<GetVocDetailsResponse> | AxiosResponse<unknown, any>> => {
    try {
      return await this.instance.get<GetVocDetailsResponse>(`${this._url}/voc/${param?.id}`);
    } catch (error) {
      return axiosErrorExceptionAsync(error);
    }
  };

  /**
   * 08. 더보기 API - 질문&답변 삭제
   * @see https://chc-app-api.huraydev.net/swagger-ui/index.html
   * @version 1.0.0
   */
  public deleteVocAsync = async ({ param }: AxiosRequest<VocParamRequest>): Promise<AxiosResponse<DeleteVocResponse> | AxiosResponse<unknown, any>> => {
    try {
      return await this.instance.delete<DeleteVocResponse>(`${this._url}/voc/${param?.id}`);
    } catch (error) {
      return axiosErrorExceptionAsync(error);
    }
  };

  /**
   * 08. 더보기 API - 프로필 조회
   * @see https://chc-app-api.huraydev.net/swagger-ui/index.html
   * @version 1.0.0
   */
  public getProfileAsync = async (): Promise<AxiosResponse<GetProfileResponse> | AxiosResponse<unknown, any>> => {
    try {
      return await this.instance.get<GetProfileResponse>(`${this._url}/profile`);
    } catch (error) {
      return axiosErrorExceptionAsync(error);
    }
  };

  /**
   * 08. 더보기 API - 메시지 목록 조회
   * @see https://chc-app-api.huraydev.net/swagger-ui/index.html
   * @version 1.0.0
   */
  public getMessagesListAsync = async (): Promise<AxiosResponse<GetMessagesListResponse> | AxiosResponse<unknown, any>> => {
    try {
      return await this.instance.get<GetMessagesListResponse>(`${this._url}/messages`);
    } catch (error) {
      return axiosErrorExceptionAsync(error);
    }
  };

  /**
   * 08. 더보기 API - 메시지 상세 조회
   * @see https://chc-app-api.huraydev.net/swagger-ui/index.html
   * @version 1.0.0
   */
  public getMessagesDetailsAsync = async ({ param }: AxiosRequest<VocParamRequest>): Promise<AxiosResponse<GetVocListResponse> | AxiosResponse<unknown, any>> => {
    try {
      return await this.instance.get<any>(`${this._url}/message/${param?.id}`);
    } catch (error) {
      return axiosErrorExceptionAsync(error);
    }
  };
}
