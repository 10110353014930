import React from 'react';
import TypographyView from '../../atoms/foundation/typography.view';
import ButtonView from '../../atoms/inputs/button.view';
import { css } from '@emotion/react';
import Colors from '../../../types/colors';
import { Link } from 'react-router-dom';
import Icon from '../../atoms/foundation/icon.view';
import { Alarm, AlarmEmpty } from '../../../assets/images/svg';
import MESSAGES from '../../../constants/messages';
import ProcessFormView from '../../atoms/processForm/process-form.view';
import useDpi from '../../../hooks/useDpi';

interface SurveyInfoSectionViewProps {
  handleSurveyBtn: () => void;
  surveyData?: any;
  isNewAlarm: boolean;
  viewportWidth: any;
  progressBars: any;
  handleInCompleteData: () => void;
}

const SurveyInfoSectionView = ({ handleSurveyBtn, surveyData, isNewAlarm, viewportWidth, progressBars, handleInCompleteData }: SurveyInfoSectionViewProps) => {
  const { primaryFontSize, secondaryFontSize, alarmSize } = useDpi();

  const wrapper = css`
    display: flex;
    flex-direction: column;
    background-color: ${Colors['Primary_100_basic']};
    border-radius: 0 0 15px 15px;
    padding: 50px 20px 20px 20px;
    //height: 20%;
  `;
  const processWrapper = css`
    display: flex;
    flex-direction: column;
    //gap: 10px;
  `;
  const doSurveyCss = css`
    margin-bottom: 10px;
  `;
  const button = css`
    margin: 0 auto;
    width: 90vw;
  `;

  const typographyWrapper = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 20px;
    gap: 10px;
    width: max-content;
  `;

  const typography = css`
    margin-bottom: 20px;
  `;
  const icon = css`
    display: flex;
    justify-content: right;
    color: #ffffff;
    a {
      color: white;
      text-decoration: none;
    }
  `;

  return (
    <div css={wrapper}>
      <div css={icon}>
        <Link to={'/dashboard/alarm/messages'}>
          <Icon width={`${alarmSize}px`} height={`${alarmSize}px`} src={isNewAlarm ? Alarm : AlarmEmpty} />
        </Link>
      </div>
      <div>
        {surveyData?.currentYearSurveyCompletionStatus === 'COMPLETE' && (
          <TypographyView bold={true} fontSize={secondaryFontSize} color={'white_regular'}>
            {surveyData?.nthCurrentYear}
            {MESSAGES.INFORMATION.SURVEY_COMPLETE}
          </TypographyView>
        )}

        {surveyData?.currentYearSurveyCompletionStatus === 'ING' && (
          <div css={processWrapper}>
            <TypographyView cssStyles={typography} component={'span'} fontSize={primaryFontSize} color={'white_regular'} bold={true}>
              {surveyData?.nthCurrentYear}
              {MESSAGES.INFORMATION.DASHBOARD_INFO.TOP}
              {viewportWidth < 768 ? <br /> : <>&nbsp;</>}
              {MESSAGES.INFORMATION.DASHBOARD_INFO.BOTTOM}
            </TypographyView>
            <ButtonView cssStyles={doSurveyCss} fontSize={secondaryFontSize} handleClick={handleInCompleteData} variant={'secondary'} text={MESSAGES.BUTTON.CONNECTING} width={'100px'} size={38} />
            <ProcessFormView progressBars={progressBars} />
          </div>
        )}
        {surveyData?.currentYearSurveyCompletionStatus === 'PRE' && ( // change PRE
          <div>
            <TypographyView gap={10} component={'span'} fontSize={primaryFontSize} color={'white_regular'} bold={true}>
              {surveyData?.nthCurrentYear}
              {MESSAGES.INFORMATION.DASHBOARD_INFO_DO_SURVEY.TOP}
              {viewportWidth < 768 ? <br /> : <>&nbsp;</>}
              {MESSAGES.INFORMATION.DASHBOARD_INFO_DO_SURVEY.BOTTOM}
            </TypographyView>
            <TypographyView cssStyles={typography} component={'span'} fontSize={secondaryFontSize} color={'white_regular'}>
              {surveyData?.nthCurrentYear && MESSAGES.INFORMATION.DASHBOARD_SUB_INFO.TOP}
              {viewportWidth < 768 ? <br /> : <>&nbsp;</>}
              {surveyData?.nthCurrentYear && MESSAGES.INFORMATION.DASHBOARD_SUB_INFO.BOTTOM}
            </TypographyView>
            <ButtonView fontSize={secondaryFontSize} cssStyles={button} handleClick={handleSurveyBtn} variant={'secondary'} text={MESSAGES.BUTTON.DO_SURVEY} width={'320px'} size={50} />
          </div>
        )}
      </div>
    </div>
  );
};

export default SurveyInfoSectionView;
