import React, { ChangeEvent } from 'react';

import { css } from '@emotion/react';
import TypographyView from '../../../../../atoms/foundation/typography.view';
import MESSAGES from '../../../../../../constants/messages';
import ButtonView from '../../../../../atoms/inputs/button.view';
import TextFieldView from '../../../../../atoms/inputs/text-field.view';
import Icon from '../../../../../atoms/foundation/icon.view';
import HeadView from '../../../../../molecules/common/head.view';
import HelperTextView from '../../../../../atoms/foundation/helper-text.view';
import Colors from '../../../../../../types/colors';
import { ArrowBack } from '../../../../../../assets/images/svg';
import useDpi from '../../../../../../hooks/useDpi';
interface ChangeNewViewProps {
  handleGoBack: () => void;
  handlePwChangeButton: () => void;
  disabled: boolean;
  newPwNumber: { onChange: (event: ChangeEvent<HTMLInputElement>) => void; maxLength: number };
  confirmPwNumber: { onChange: (event: ChangeEvent<HTMLInputElement>) => void; maxLength: number };
  newHelperText: string;
  confirmHelperText: string;
}

const ProfileNewPasswordView = ({ handleGoBack, handlePwChangeButton, disabled, newPwNumber, confirmPwNumber, newHelperText, confirmHelperText }: ChangeNewViewProps) => {
  const { subFontSize, listTitle, miniFontSize, miniIcons } = useDpi();
  const top = css`
    background-color: ${Colors['white_regular']};
    height: 50px;
  `;
  const wrapper = css`
    display: flex;
    flex-direction: column;
    background-color: ${Colors['Grey_01_bg']};
    height: calc(100vh - 50px);
  `;

  const textField = css`
    border-bottom: 1px solid ${Colors['Grey_02_line']};
  `;

  const password = css`
    height: 63px;
    padding-left: 21px;
    border-bottom: none;
    color: ${Colors['Black_basic']};
    width: calc(100vw - 25px);
    font-size: ${subFontSize}px;
    &::placeholder {
      font-size: ${listTitle}px;
    }
  `;

  const info = css`
    display: flex;
    padding: 12px 28px 0 21px;
  `;

  const nextButton = css`
    display: flex;
    position: fixed;
    bottom: 0;
    line-height: 15px;
  `;

  const rectangleWrapper = css`
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    gap: 5px;
    margin-left: 15px;
    font-size: ${miniFontSize}px;
  `;

  const rectangle = css`
    display: flex;
    background-color: red;
    width: 13px;
    height: 13px;
    align-items: center;
    text-align: center;
    justify-content: center;
    vertical-align: center;
  `;

  return (
    <div css={wrapper}>
      <div css={top}></div>
      <HeadView handleClick={handleGoBack} icon={<Icon src={ArrowBack} width={`${miniIcons}px`} height={`${miniIcons}px`} />} header={MESSAGES.HEAD_TITLES.NEW_PW} />
      <TextFieldView boxStyle={textField} css={password} {...newPwNumber} type={'password'} placeholder={MESSAGES.PLACEHOLDER.NEW_PW} placeholderLeft={true} />
      <TextFieldView boxStyle={textField} css={password} {...confirmPwNumber} type={'password'} placeholder={MESSAGES.PLACEHOLDER.CONFIRM_NEW_PW} placeholderLeft={true} />
      {newHelperText && (
        <HelperTextView
          child={
            <div css={rectangleWrapper}>
              <div css={rectangle} />
              {newHelperText}
            </div>
          }
        />
      )}
      {confirmHelperText && (
        <HelperTextView
          child={
            <div css={rectangleWrapper}>
              <div css={rectangle} />
              {confirmHelperText}
            </div>
          }
        />
      )}
      <div css={info}>
        <TypographyView fontSize={12} color={'Grey_05_subtext'}>
          {MESSAGES.INFORMATION.PW_VALIDATION_INFO}
        </TypographyView>
      </div>
      <ButtonView handleClick={handlePwChangeButton} size={56} rounded={'0'} cssStyles={nextButton} variant={`${disabled ? 'disable' : 'primary'}`} disabled={disabled} text={MESSAGES.BUTTON.EDIT} />
    </div>
  );
};

export default ProfileNewPasswordView;
