import React, { useEffect, useRef, useState } from 'react';

import ReportPartsNavbarView from './report-part-navbar.view';

interface ReportPartsNavbarViewProps {
  activeButton: string;
  handleButtonClick: any;
  reportDetailData: any;
  buttonData: any;
}

const ReportPartsNavbar = ({ buttonData, activeButton, handleButtonClick }: ReportPartsNavbarViewProps) => {
  const [combinedWidth, setCombinedWidth] = useState<any>();
  const viewport = window.innerWidth;
  useEffect(() => {
    const hiddenDiv = document.createElement('div');
    hiddenDiv.style.visibility = 'hidden';
    hiddenDiv.style.whiteSpace = 'nowrap';
    hiddenDiv.style.position = 'absolute';
    hiddenDiv.style.top = '-9999px';
    document.body.appendChild(hiddenDiv);

    let combinedWidth = 0;
    buttonData?.forEach((value: { text: string }) => {
      hiddenDiv.innerText = value.text;
      combinedWidth += hiddenDiv.offsetWidth;
    });

    document.body.removeChild(hiddenDiv);

    setCombinedWidth(combinedWidth);
  }, [buttonData]);
  const filteredData = buttonData.filter((value: { value: any }, index: any, self: any[]) => index === self.findIndex((item) => item.value === value.value));
  const props = {
    filteredData,
    activeButton,
    handleButtonClick,
    combinedWidth,
    viewport,
  };

  return <ReportPartsNavbarView {...props} />;
};

export default ReportPartsNavbar;
