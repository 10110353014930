import React from 'react';
import RowListView from '../../../molecules/common/row-list.view';
import TypographyView from '../../../atoms/foundation/typography.view';
import ButtonView from '../../../atoms/inputs/button.view';
import { css } from '@emotion/react';
import Colors from '../../../../types/colors';
import { Link } from 'react-router-dom';
import HeadView from '../../../molecules/common/head.view';
import Icon from '../../../atoms/foundation/icon.view';
import BackArrow from '../../../../assets/images/svg/icons_arrow_back.svg';
import MESSAGES from '../../../../constants/messages';
import { ArrowNext } from '../../../../assets/images/svg';
import useDpi from '../../../../hooks/useDpi';

interface MoreProfileView {
  handleCopyButton: () => void;
  handleLogoutButton?: () => void;
  handleGoBack: () => void;
  profileData: any;
}

const MoreProfileView = ({ handleCopyButton, handleLogoutButton, handleGoBack, profileData }: MoreProfileView) => {
  const { subFontSize, miniIcons } = useDpi();
  const top = css`
    height: 50px;
    background-color: ${Colors.white_regular};
  `;
  const wrapper = css`
    background-color: ${Colors.Grey_01_bg};
    width: 100%;
    height: 100%;
  `;
  const typography = css`
    display: flex;
    align-items: center;
    padding-left: 21px;
    height: 40px;
  `;
  const rowList = css`
    font-size: ${subFontSize}px;
    height: 66px;
    padding-left: 21px;
  `;
  const profileDataStyle = css`
    padding-right: 21px;
  `;

  const copyBtnWrapper = css`
    display: flex;
    align-items: center;
    font-size: ${subFontSize}px;
    margin-right: 21px;
  `;
  const buttonStyle = css`
    height: 38px;
    width: 73px;
    margin-left: 15px;
  `;
  const logoutBtnWrapper = css`
    background-color: ${Colors['white_regular']};
    margin-top: 15px;
    height: 40px;
    display: flex;
    align-items: center;
  `;
  const logoutBtn = css`
    color: ${Colors['Grey_04_inactive']};
    font-weight: 600;
    font-size: ${subFontSize}px;
  `;

  return (
    <div css={wrapper}>
      <div css={top} />
      <HeadView handleClick={handleGoBack} icon={<Icon src={BackArrow} width={`${miniIcons}px`} height={`${miniIcons}px`} />} header={MESSAGES.HEAD_TITLES.PROFILE} />
      <div css={typography}>
        <TypographyView fontSize={subFontSize} bold={true} color={'Grey_04_inactive'}>
          {MESSAGES.ROW_TITLES.ACC_INFO}
        </TypographyView>
      </div>
      <RowListView
        cssStyle={rowList}
        title={MESSAGES.ROW_TITLES.ID}
        value={<TypographyView cssStyles={profileDataStyle} fontSize={subFontSize} color={'Grey_04_inactive'} children={profileData?.loginId} />}
        isValue={true}
      />
      <Link to={'/more/profile/change-password'} style={{ textDecoration: 'none' }}>
        <RowListView
          cssStyle={rowList}
          title={MESSAGES.ROW_TITLES.PW_CHANGE}
          value={
            <div style={{ paddingRight: '21px' }}>
              <Icon src={ArrowNext} width={`${miniIcons}px`} height={`${miniIcons}px`} />
            </div>
          }
          isValue={true}
        />
      </Link>
      <div css={typography}>
        <TypographyView fontSize={subFontSize} bold={true} color={'Grey_04_inactive'}>
          {MESSAGES.ROW_TITLES.USER_INFO}
        </TypographyView>
      </div>
      <RowListView
        cssStyle={rowList}
        title={MESSAGES.ROW_TITLES.NAME}
        value={<TypographyView cssStyles={profileDataStyle} fontSize={subFontSize} color={'Grey_04_inactive'} children={profileData?.loginName} />}
        isValue={true}
      />
      <RowListView
        cssStyle={rowList}
        title={MESSAGES.ROW_TITLES.BIRTHDATE}
        value={<TypographyView cssStyles={profileDataStyle} fontSize={subFontSize} color={'Grey_04_inactive'} children={profileData?.loginBirthDate?.replace(/-/g, '.')} />}
        isValue={true}
      />
      <RowListView
        cssStyle={rowList}
        title={MESSAGES.ROW_TITLES.SEX}
        value={<TypographyView cssStyles={profileDataStyle} fontSize={subFontSize} color={'Grey_04_inactive'} children={profileData?.loginGender === 'M' ? '남자' : '여자'} />}
        isValue={true}
      />
      <RowListView
        title={MESSAGES.ROW_TITLES.ENGINE_CODE}
        cssStyle={rowList}
        value={
          <div css={copyBtnWrapper}>
            <TypographyView color={'Grey_04_inactive'} fontSize={subFontSize} children={profileData?.certificationCode} /> &nbsp;
            <ButtonView cssStyles={buttonStyle} handleClick={handleCopyButton} fontSize={subFontSize} rounded={'15px'} variant={'primary'} text={`복사`} width={'65px'} />
          </div>
        }
        isValue={true}
      />
      <div css={typography}>
        <TypographyView fontSize={subFontSize} bold={true} color={'Grey_04_inactive'}>
          {MESSAGES.ROW_TITLES.FAMILY_INFO}
        </TypographyView>
      </div>
      <RowListView
        cssStyle={rowList}
        title={profileData?.familyType === 'C' ? MESSAGES.ROW_TITLES.CHILD_NAME : MESSAGES.ROW_TITLES.PARENT_NAME}
        value={<TypographyView cssStyles={profileDataStyle} fontSize={subFontSize} color={'Grey_04_inactive'} children={profileData?.familyName} />}
        isValue={true}
      />
      <RowListView
        cssStyle={rowList}
        title={profileData?.familyType === 'C' ? MESSAGES.ROW_TITLES.CHILD_BIRTHDATE : MESSAGES.ROW_TITLES.PARENT_BIRTHDATE}
        value={<TypographyView cssStyles={profileDataStyle} fontSize={subFontSize} color={'Grey_04_inactive'} children={profileData?.familyBirthDate?.replace(/-/g, '.')} />}
        isValue={true}
      />
      <RowListView
        cssStyle={rowList}
        title={MESSAGES.ROW_TITLES.PATIENT_SEX}
        value={<TypographyView cssStyles={profileDataStyle} fontSize={subFontSize} color={'Grey_04_inactive'} children={profileData?.familyGender === 'M' ? '남자' : '여자'} />}
        isValue={true}
      />
      <div css={typography}>
        <TypographyView fontSize={subFontSize} bold={true} color={'Grey_04_inactive'}>
          담당 관리자
        </TypographyView>
      </div>
      <RowListView
        cssStyle={rowList}
        title={MESSAGES.ROW_TITLES.PHONE_NUMBER}
        value={
          <TypographyView cssStyles={profileDataStyle} fontSize={subFontSize} color={'Grey_04_inactive'} children={profileData?.managerTelno.length === 0 ? '비공개' : profileData?.managerTelno} />
        }
        isValue={true}
      />
      <RowListView
        cssStyle={rowList}
        title={MESSAGES.ROW_TITLES.SELF_PHONE_NUMBER}
        value={
          <TypographyView cssStyles={profileDataStyle} fontSize={subFontSize} color={'Grey_04_inactive'} children={profileData?.managerCpTelno.length === 0 ? '비공개' : profileData?.managerCpTelno} />
        }
        isValue={true}
      />
      <div css={logoutBtnWrapper}>
        <ButtonView handleClick={handleLogoutButton} cssStyles={logoutBtn} variant={'secondary'} text={MESSAGES.BUTTON.LOGOUT} width={'85px'} />
      </div>
    </div>
  );
};

export default MoreProfileView;
