import React from 'react';
import MessageView from '../../../atoms/foundation/message.view';
import NoMessageView from '../../../atoms/alert/no-message.view';
import { ImageEmptyQnA } from '../../../../assets/images/svg';
import MESSAGES from '../../../../constants/messages';
import useViewportHeight from '../../../../hooks/useViewportHeight';
import { css } from '@emotion/react';

interface QnAProps {
  data?: any;
}

const QuestionAndAnswer = ({ data }: QnAProps) => {
  const isMessage = data?.length > 0;

  const viewportHeight = useViewportHeight();

  const height = viewportHeight - 105;
  const wrapper = css`
    // height: ${height + 'px'};
    overflow-y: scroll;
    scroll-behavior: smooth;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
  `;

  return (
    <div css={wrapper}>
      {isMessage ? (
        data.map((voc: { sendAt: string; content: string; isRead: boolean; vocId: number }) => (
          <MessageView message={voc} key={voc.vocId} maxLines={3} height={height} to={`/more/question-answer/${voc.vocId}`} />
        ))
      ) : (
        <NoMessageView icon={ImageEmptyQnA} text={MESSAGES.INFORMATION.EMPTY_QNA} height={'85vh'} />
      )}
    </div>
  );
};

export default QuestionAndAnswer;
