import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MoreQuestionDetailsView from './question-detail.view';
import { EtcApi } from '../../../../../apis/v1/etc.api';
import useAuthentication from '../../../../../store/useAuthentication';

const MoreQuestionDetail = () => {
  const { authentication } = useAuthentication();

  const etcApi = new EtcApi(process.env.REACT_APP_ENV !== 'dev', authentication.accessToken, authentication.deviceId);
  const [questionDetailData, setQuestionDetailData] = useState<any>('');
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (questionDetailData.length !== 0 && authentication.accessToken.length !== 0) {
      setIsLoading(false);
    }
  }, [questionDetailData, authentication.accessToken]);

  useEffect(() => {
    if (authentication.accessToken && authentication.deviceId) {
      const fetchData = async () => {
        try {
          const { data }: any = await etcApi.getVocDetailsAsync({ param: { id: Number(id) } });

          setQuestionDetailData(data);
        } catch (error) {
          // await window.flutter_inappwebview.callHandler('goErrorPage', '');
        }
      };
      fetchData();
    }
  }, [authentication.accessToken, authentication.deviceId]);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleQuestionDelete = async () => {
    if (process.env.REACT_APP_BUILD === 'flutter') {
      // @ts-ignore
      const response = await window.flutter_inappwebview.callHandler('showDialogDelete');
      if (response) {
        try {
          const { data }: any = await etcApi.deleteVocAsync({ param: { id: Number(id) } });
          if (data?.message === 'success') {
            // @ts-ignore
            await window.flutter_inappwebview.callHandler('showToastMessage', '메세지가 삭제되었습니다.');
            navigate(-1);
          } else {
            // @ts-ignore
            await window.flutter_inappwebview.callHandler('showToastMessage', '메세지 삭제가 실패하였습니다.');
          }
        } catch (error) {
          // @ts-ignore
          await window.flutter_inappwebview.callHandler('showToastMessage', '메세지 삭제가 실패하였습니다.');
        }
      }
    } else {
      try {
        const { data }: any = await etcApi.deleteVocAsync({ param: { id: Number(id) } });
        if ((data.message = 'success')) {
          console.log('deleted');
          navigate(-1);
        } else {
          console.log('failed');
          navigate('/index.html');
        }
      } catch (error) {
        await window.flutter_inappwebview.callHandler('goErrorPage', '');
      }
    }
  };
  const managerAnswer = questionDetailData?.managerAnswer?.split('\n');
  const props = {
    questionDetailData,
    handleGoBack,
    handleQuestionDelete,
    isLoading,
    managerAnswer,
  };

  return <MoreQuestionDetailsView {...props} />;
};

export default MoreQuestionDetail;
