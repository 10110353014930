import create from 'zustand';

interface AuthenticationStore {
  authentication: {
    accessToken: string;
    deviceId: string;
    appVersion: string;
  };
  setAuthentication: (state: any) => void;
}

const useAuthentication = create<AuthenticationStore>((set) => ({
  authentication: {
    accessToken: '',
    deviceId: '',
    appVersion: '',
  },
  setAuthentication: (state: any) => set({ authentication: state }),
}));

export default useAuthentication;
