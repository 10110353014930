import React from 'react';
import { css } from '@emotion/react';
import Survey from '../../organisms/survey/survey';
import Colors from '../../../types/colors';

interface SurveyPageViewProps {
  surveyPageData: any;
}

const SurveyPageView = ({ surveyPageData }: SurveyPageViewProps) => {
  const wrapper = css`
    //width: 100%;
    background-color: ${Colors['Grey_01_bg']};
    height: calc(100vh);
  `;

  return (
    <div css={wrapper}>
      <Survey surveyPageData={surveyPageData} />
    </div>
  );
};

export default SurveyPageView;
