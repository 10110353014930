import React from 'react';
import { css } from '@emotion/react';
import Colors from '../../../../types/colors';
import TypographyView from '../../../atoms/foundation/typography.view';
import HeadView from '../../../molecules/common/head.view';
import Icon from '../../../atoms/foundation/icon.view';
import BackArrow from '../../../../assets/images/svg/icons_arrow_back.svg';
import MESSAGES from '../../../../constants/messages';
import MessageView from '../../../atoms/foundation/message.view';
import DropdownView from '../../../atoms/inputs/dropdown.view';
import LoadingSpinner from '../../../atoms/foundation/loading-spinner.view';
import useDpi from '../../../../hooks/useDpi';
import NoMessageView from '../../../atoms/alert/no-message.view';
import { ImageEmptyMessage } from '../../../../assets/images/svg';

export interface MessageProps {
  handleGoBack: () => void;
  filter: 'all' | 'unread';
  onChange: (e: { target: { value: string } }) => void;
  filteredMessages: { messageId: number; messageTitle: string; messageAt: string; isRead: boolean }[];
  height: number;
  isLoading: boolean;
}

const MoreMessageListView = ({ handleGoBack, filter, onChange, filteredMessages, height, isLoading }: MessageProps) => {
  const { miniIcons } = useDpi();
  const top = css`
    overflow: hidden;
    position: fixed;
    top: 0;
    height: 50px;
    width: 100%;
    z-index: 1;
    background-color: ${Colors['white_regular']};
  `;

  const wrapper = css`
    width: 100%;
    height: calc(100vh - 50px);
    background-color: ${Colors.Grey_01_bg};
    padding-top: 50px;
  `;

  const headAndDropdownWrapper = css`
    position: fixed;
    z-index: 1;
    top: 50px;
    width: 100vw;
    background-color: ${Colors['Grey_01_bg']};
  `;

  const messageWrapper = css`
    // height:${height + 'px'};
    overflow-y: scroll;
    scroll-behavior: smooth;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none;
    padding-top: 100px;
    ::-webkit-scrollbar {
      display: none;
    }
  `;

  const typography = css`
    background-color: ${Colors['Grey_01_bg']};
    color: ${Colors['Grey_05_subtext']};
    padding: 26px 20px 25px;
    font-weight: bold;
    font-size: 14px;
  `;

  const spinnerWrapper = css`
    display: flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    align-items: center;
    margin: 230px auto;
  `;

  return (
    <div css={wrapper}>
      <div css={top}></div>
      <div css={headAndDropdownWrapper}>
        <HeadView handleClick={handleGoBack} icon={<Icon src={BackArrow} width={`${miniIcons}px`} height={`${miniIcons}px`} />} header={MESSAGES.HEAD_TITLES.MESSAGE} />
        <DropdownView value={filter} onChange={onChange} />
      </div>
      {isLoading ? (
        <div css={spinnerWrapper}>
          <LoadingSpinner />
        </div>
      ) : (
        <div css={messageWrapper}>
          {filteredMessages.length !== 0 ? (
            filteredMessages?.map((message: { messageId: number; messageTitle: string; isRead: boolean; messageAt: string }) => (
              <MessageView message={message} key={message.messageId} height={height} maxLines={3} to={`/more/messages/${message.messageId}`} />
            ))
          ) : (
            <NoMessageView icon={ImageEmptyMessage} text={MESSAGES.INFORMATION.EMPTY_MESSAGE} height={'67vh'} />
          )}
        </div>
      )}
      <TypographyView cssStyles={typography}>{MESSAGES.INFORMATION.MESSAGE_AVAILABLE_INFO}</TypographyView>
    </div>
  );
};

export default MoreMessageListView;
