import React, { useEffect, useState } from 'react';

import DashboardView from './dashboard.view';

interface DashboardViewProps {
  dashboardPageData: any;
  reportPageData: any;
  isLoading: boolean;
}
interface SurveyNameMap {
  [key: string]: string;
}
const Dashboard = ({ dashboardPageData, reportPageData, isLoading }: DashboardViewProps) => {
  const [surveyNameMap, setSurveyNameMap] = useState<SurveyNameMap>({});

  useEffect(() => {
    if (reportPageData && reportPageData.dataList) {
      const map: SurveyNameMap = {};
      reportPageData.dataList.forEach((item: { surveyType: string; surveyName: string }) => {
        map[item.surveyType] = item.surveyName;
      });
      setSurveyNameMap(map);
    }
  }, [reportPageData]);
  const props = {
    reportPageData,
    dashboardPageData,
    surveyNameMap,
    isLoading,
  };

  return <DashboardView {...props} />;
};

export default Dashboard;
