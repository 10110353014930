import React, { ReactElement } from 'react';
import TypographyView from '../../atoms/foundation/typography.view';
import { css } from '@emotion/react';
import Colors from '../../../types/colors';
import { Link } from 'react-router-dom';
import MESSAGES from '../../../constants/messages';
import useDpi from '../../../hooks/useDpi';

interface HeadViewProps {
  handleClick: () => void;
  icon: ReactElement<any, any>;
  username: string;
}

const MoreHeadView = ({ icon, username }: HeadViewProps) => {
  const { secondaryFontSize } = useDpi();
  const head = css`
    flex-direction: row;
    background-color: ${Colors['white_regular']};
    vertical-align: center;
    border-bottom: 1px solid ${Colors.Grey_02_line};
    a {
      display: flex;
      justify-content: space-between;
      text-decoration: none;
      padding-left: 21px;
      height: 66px;
      align-items: center;
    }
  `;
  const iconStyle = css`
    padding-right: 21px;
  `;
  const headStyle = css`
    font-weight: 800 !important;
  `;

  return (
    <div css={head}>
      <Link to={'/more/profile'}>
        <TypographyView cssStyles={headStyle} fontSize={secondaryFontSize} color={'Black_basic'}>
          {username} &nbsp;
          {MESSAGES.HEAD_TITLES.CLIENT}
        </TypographyView>
        <div css={iconStyle}>{icon}</div>
      </Link>
    </div>
  );
};

export default MoreHeadView;
