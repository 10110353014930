import React, { ChangeEvent, SetStateAction, useState } from 'react';
import HeadView from '../../../../../molecules/common/head.view';
import RowListView from '../../../../../molecules/common/row-list.view';
import TextFieldView from '../../../../../atoms/inputs/text-field.view';
import ButtonView from '../../../../../atoms/inputs/button.view';
import { css } from '@emotion/react';
import Colors from '../../../../../../types/colors';
import TypographyView from '../../../../../atoms/foundation/typography.view';
import BackArrow from '../../../../../../assets/images/svg/icons_arrow_back.svg';
import Icon from '../../../../../atoms/foundation/icon.view';
import MESSAGES from '../../../../../../constants/messages';
import HelperTextView from '../../../../../atoms/foundation/helper-text.view';
import Checkbox from '../../../../../atoms/inputs/checkbox.view';
import useDpi from '../../../../../../hooks/useDpi';
import { ArrowDown } from '../../../../../../assets/images/svg';
import Timer from '../../../../../atoms/timer/timer';
import colors from '../../../../../../types/colors';
interface UserIdentificationViewProps {
  nameField: any;
  phoneNumberField: any;
  engineCodeField: any;
  handleGoBack: () => void;
  phoneNumberDisabled: boolean;
  engineCodeDisabled: boolean;
  handleNextBtn: () => void;
  nextBtnDisabled: boolean;
  validationError: string;
  handleCheckboxChange: any;
  gender: string;
  handleEngineCode: () => void;
  handlePhNumber: () => void;
  helperText: string;
  openDatepicker: any;
  birthDate: string;
  startTimer: boolean;
  handleTimeElapsed: () => void;
  verified: boolean;
  openTimeElapsedAlert: boolean;
}

const UserIdentificationView = ({
  nameField,
  phoneNumberField,
  engineCodeField,
  handleGoBack,
  phoneNumberDisabled,
  engineCodeDisabled,
  handleNextBtn,
  nextBtnDisabled,
  validationError,
  handleCheckboxChange,
  gender,
  handleEngineCode,
  handlePhNumber,
  helperText,
  openDatepicker,
  birthDate,
  startTimer,
  handleTimeElapsed,
  verified,
  openTimeElapsedAlert,
}: UserIdentificationViewProps) => {
  const { subFontSize, secondaryFontSize, miniFontSize, miniIcons, listTitle } = useDpi();
  const top = css`
    background-color: ${Colors['white_regular']};
    height: 50px;
  `;
  const wrapper = css`
    background-color: ${Colors['Grey_01_bg']};
    height: calc(100vh - 50px);
  `;
  const rowListTitle = css`
    height: 66px;
    padding-left: 21px;
    font-size: ${subFontSize}px;
  `;
  const NameInput = css`
    width: 182px;
    font-size: ${listTitle}px;
    padding-right: 21px;
    //&::placeholder {
    //  padding-right: 21px;
    //}
  `;
  const phoneNumberInput = css`
    width: 120px;
    font-size: ${listTitle}px;
  `;
  const engineInput = css`
    width: 60px;
    font-size: ${listTitle}px;
  `;

  const engineCodeWrapper = css`
    display: flex;
    gap: 15px;
    width: max-content;
    :last-of-type {
      padding-right: 21px;
    }
  `;

  const info = css`
    padding: 13px 21px 0 28px;
  `;

  const checkboxWrapper = css`
    display: flex;
    color: ${gender ? Colors['Grey_03_regular'] : Colors['Black_basic']};
    font-size: 14px;
    gap: 29px;
    :first-of-type {
      margin-right: 21px;
    }
    div {
      display: flex;
      //gap: 10px;
    }
  `;

  const male = css`
    color: ${gender === 'M' ? Colors['Black_basic'] : Colors['Grey_03_regular']};
    font-size: ${listTitle}px;
  `;
  const female = css`
    color: ${gender === 'F' ? Colors['Black_basic'] : Colors['Grey_03_regular']};
    font-size: ${listTitle}px;
  `;

  const engineButtons = css`
    width: 75px;
    padding: 8px 0;
  `;

  const nextButton = css`
    position: fixed;
    bottom: 0;
    line-height: 15px;
  `;

  const errorWrapper = css`
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    gap: 5px;
    margin-left: 15px;
  `;

  const datePicker = css`
    color: ${Colors['Grey_03_regular']};
    font-size: ${listTitle}px;
    padding-right: 21px;
  `;
  return (
    <div css={wrapper}>
      <div css={top}></div>
      <HeadView handleClick={handleGoBack} icon={<Icon src={BackArrow} width={`${miniIcons}px`} height={`${miniIcons}px`} />} header={MESSAGES.HEAD_TITLES.ENGINE_CODE} />
      <RowListView cssStyle={rowListTitle} title={MESSAGES.ROW_TITLES.NAME} isValue={true} value={<TextFieldView css={NameInput} {...nameField} />} />
      <RowListView
        cssStyle={rowListTitle}
        title={MESSAGES.ROW_TITLES.BIRTHDATE}
        isValue={true}
        value={
          <ButtonView
            cssStyles={datePicker}
            variant={'secondary'}
            handleClick={openDatepicker}
            text={
              <div style={{ display: 'flex', gap: '8px', color: birthDate === '선택' ? colors.Grey_03_regular : colors.Black_basic }}>
                {birthDate} <Icon src={ArrowDown} width={`${miniIcons}px`} height={`${miniIcons}px`} />
              </div>
            }
          />
        }
      />
      <RowListView
        cssStyle={rowListTitle}
        title={MESSAGES.ROW_TITLES.SEX}
        isValue={true}
        value={
          <div css={checkboxWrapper}>
            <div css={male}>
              <Checkbox gender={MESSAGES.PLACEHOLDER.MALE} checked={gender === 'M'} onChange={handleCheckboxChange} text={'남자'} />
            </div>
            <div css={female}>
              <Checkbox gender={MESSAGES.PLACEHOLDER.FEMALE} checked={gender === 'F'} onChange={handleCheckboxChange} text={'여자'} />
            </div>
          </div>
        }
      />
      <RowListView
        cssStyle={rowListTitle}
        title={MESSAGES.ROW_TITLES.PHONE_NUMBER}
        isValue={true}
        value={
          <div css={engineCodeWrapper}>
            <TextFieldView css={phoneNumberInput} {...phoneNumberField} />
            <ButtonView
              cssStyles={engineButtons}
              variant={`${phoneNumberDisabled ? 'disable' : 'primary'}`}
              disabled={phoneNumberDisabled}
              text={MESSAGES.BUTTON.AUTH_REQUEST}
              handleClick={handlePhNumber}
            />
          </div>
        }
      />
      <RowListView
        cssStyle={rowListTitle}
        title={
          <div style={{ display: 'flex', gap: 5 }}>
            {MESSAGES.ROW_TITLES.ENGINE_NUMBER} {startTimer && <Timer shouldStart={startTimer} onTimeElapsed={handleTimeElapsed} />}
          </div>
        }
        isValue={true}
        value={
          <div css={engineCodeWrapper}>
            <TextFieldView css={engineInput} {...engineCodeField} />
            <ButtonView
              variant={`${engineCodeDisabled ? 'disable' : 'primary'}`}
              disabled={engineCodeDisabled}
              text={verified ? MESSAGES.BUTTON.VERIFIED : MESSAGES.BUTTON.CERTIFY}
              handleClick={handleEngineCode}
              cssStyles={engineButtons}
            />
          </div>
        }
      />
      <div css={info}>
        <TypographyView color={'Red_regular'}>{helperText}</TypographyView>
        {validationError && <HelperTextView child={<div css={errorWrapper}>{validationError}</div>} />}
        <TypographyView fontSize={miniFontSize} color={'Grey_05_subtext'}>
          {MESSAGES.INFORMATION.PHONE_NUMBER_INFO}
        </TypographyView>
      </div>
      <ButtonView
        handleClick={handleNextBtn}
        size={56}
        rounded={'0'}
        cssStyles={nextButton}
        variant={`${nextBtnDisabled ? 'disable' : 'primary'}`}
        disabled={nextBtnDisabled}
        text={MESSAGES.BUTTON.NEXT}
      />
    </div>
  );
};

export default UserIdentificationView;
