import React, { ReactElement } from 'react';
import { css } from '@emotion/react';
import Colors from '../../../types/colors';
import useDpi from '../../../hooks/useDpi';
import BarChart from '../../atoms/chart/bar-chart';

interface BarChartSectionViewProps {
  icon?: any;
  moreBtn?: ReactElement<any, any>;
  data?: any;
  iconName?: any;
}

const BarChartSectionView = ({ icon, moreBtn, data, iconName }: BarChartSectionViewProps) => {
  const { iconFontSize, secondaryFontSize } = useDpi();
  const wrapper = css`
    display: flex;
    flex-direction: column;
    background-color: ${Colors.white_regular};
    padding: 15px 10px;
    margin: 10px;
    border-radius: 15px;
  `;

  const iconWrapper = css`
    display: flex;
    justify-content: space-between;
  `;
  const iconStyle = css`
    display: flex;
    align-items: center;
    width: max-content;
    font-size: ${iconFontSize}px;
    font-weight: bold;
    span {
      font-size: ${secondaryFontSize}px;
    }
  `;
  return (
    <div css={wrapper}>
      <div css={iconWrapper}>
        <div css={iconStyle}>
          <div>{icon}</div>
          <span>{iconName}</span>
        </div>
        <div>{moreBtn}</div>
      </div>
      <BarChart BarData={data} />
    </div>
  );
};

export default BarChartSectionView;
