import React from 'react';
import { css } from '@emotion/react';
import { SerializedStyles } from '@emotion/serialize/dist/emotion-serialize.cjs';

interface HelperTextViewProps {
  child?: any;
  cssStyles?: SerializedStyles;
}

const HelperTextView = ({ child, cssStyles }: HelperTextViewProps) => {
  const textStyle = css`
    display: flex;
    color: red;
    font-size: 14px;
    ${{ ...cssStyles }}
  `;

  return <div css={textStyle}>{child}</div>;
};

export default HelperTextView;
