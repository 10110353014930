import React from 'react';
import { css } from '@emotion/react';

interface IconProps {
  src: any;
  width: string;
  height: string;
}

const Icon = ({ src, width, height }: IconProps) => {
  const iconWrapper = css`
    width: ${width};
    height: ${height};
  `;
  return (
    <div>
      <img css={iconWrapper} src={src} />
    </div>
  );
};

export default Icon;
