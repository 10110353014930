import React from 'react';
import useDpi from '../../../hooks/useDpi';
import SurveyMiddleSectionView from './survey-middle-section.view';

interface SurveyMiddleSectionProps {
  surveyPageData: any;
}
const SurveyMiddleSection = ({ surveyPageData }: SurveyMiddleSectionProps) => {
  const { headerFontSize } = useDpi();
  const yearText = surveyPageData?.year ? `${surveyPageData.year}년 (${surveyPageData.nthCurrentYear}차년도)` : '';

  const props = {
    headerFontSize,
    yearText,
    surveyPageData,
  };

  return <SurveyMiddleSectionView {...props} />;
};

export default SurveyMiddleSection;
