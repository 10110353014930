import React from 'react';
import SurveyBodySection from '../../molecules/survey/survey-body-section';
import { css } from '@emotion/react';
import Colors from '../../../types/colors';
import SurveyInfoSection from '../../molecules/common/survey-info-section';
import SurveyMiddleSection from '../../molecules/survey/survey-middle-section';

interface SurveyViewProps {
  surveyPageData: any;
}

const SurveyView = ({ surveyPageData }: SurveyViewProps) => {
  const wrapper = css`
    background-color: ${Colors['Grey_01_bg']};
    //width: 100%;
  `;
  return (
    <div css={wrapper}>
      <SurveyInfoSection />
      <SurveyMiddleSection surveyPageData={surveyPageData} />
      <SurveyBodySection surveyPageData={surveyPageData} />
    </div>
  );
};

export default SurveyView;
