import React from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import Icon from '../../../atoms/foundation/icon.view';
import { ListItem } from './more-terms';
import MESSAGES from '../../../../constants/messages';
import RowListView from '../../../molecules/common/row-list.view';
import HeadView from '../../../molecules/common/head.view';
import { ArrowBack } from '../../../../assets/images/svg';
import useDpi from '../../../../hooks/useDpi';

interface MoreTermsViewProps {
  handleGoBack: () => void;
  listItem: ListItem[];
  location: any;
}

const MoreTermsView = ({ handleGoBack, listItem, location }: MoreTermsViewProps) => {
  const { miniIcons, subFontSize } = useDpi();
  const wrapper = css`
    width: 100%;
    height: 100%;
    background-color: white;
    a {
      text-decoration: none;
    }
    padding-top: 50px;
  `;

  const rowListTitle = css`
    height: 66px;
    padding-left: 21px;
    font-size: ${subFontSize}px;
  `;

  const iconStyles = css`
    padding-right: 21px;
  `;

  return (
    <div css={wrapper}>
      <HeadView handleClick={handleGoBack} icon={<Icon src={ArrowBack} width={`${miniIcons}px`} height={`${miniIcons}px`} />} header={MESSAGES.HEAD_TITLES.TERM_AND_CONDITION} />
      {listItem.map((item: { pathname: string; title: string; value: string }) => (
        <Link key={item.pathname} to={`${location.pathname}/${item.pathname}`}>
          <RowListView cssStyle={rowListTitle} title={item.title} isValue={true} value={<div css={iconStyles}>{item.value}</div>} />
        </Link>
      ))}
    </div>
  );
};

export default MoreTermsView;
