import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardAlarmView from './dashboard-alarm.view';

import { AlarmAPI } from '../../../../apis/v1/alarm.api';
import useAuthentication from '../../../../store/useAuthentication';
import useViewportHeight from '../../../../hooks/useViewportHeight';

const DashboardAlarm = () => {
  const { authentication } = useAuthentication();

  const alarmApi = new AlarmAPI(process.env.REACT_APP_ENV !== 'dev', authentication.accessToken, authentication.deviceId);
  const [alarmData, setAlarmData] = useState<any>('');
  const navigate = useNavigate();
  const [activeNavbar, setActiveNavbar] = useState('messages');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (alarmData.messageList?.length !== undefined && alarmData.vocList?.length !== undefined && authentication.accessToken.length !== 0) {
      setIsLoading(false);
    }
  }, [alarmData, authentication.accessToken]);
  const viewportHeight = useViewportHeight();

  const height = viewportHeight - 150;

  useEffect(() => {
    if (authentication.accessToken && authentication.deviceId) {
      const fetchData = async () => {
        try {
          const { data }: any = await alarmApi.getAsync();

          setAlarmData(data);
        } catch (error) {
          // await window.flutter_inappwebview.callHandler('goErrorPage', '');
        }
      };
      fetchData();
    }
  }, [authentication.accessToken, authentication.deviceId]);

  const handleNavbar = (navbar: string) => {
    setActiveNavbar(navbar);
  };

  const handleGoBack = () => {
    navigate('/');
  };

  const props = {
    handleGoBack,
    activeNavbar,
    handleNavbar,
    alarmData,
    isLoading,
    height,
  };

  return <DashboardAlarmView {...props} />;
};

export default DashboardAlarm;
