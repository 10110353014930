import React from "react";
import { useNavigate } from "react-router-dom";
import MoreVersionView from "./more-version.view";

const MoreVersion = () => {
  const navigate = useNavigate()

  const handleGoBack = () => {
    navigate(-1)
  }

  const props = {
    handleGoBack
  }

  return (
   <MoreVersionView {...props} />
  );
};

export default MoreVersion;
