import { AxiosResponse } from 'axios';
import { AxiosConfig, axiosErrorExceptionAsync, AxiosRequest } from '../axios.config';

export type SurveyParamRequest = {
  surveyId: number;
  subScaleId: number;
};

export type PostSubScaleRequest = {
  surveyId: number;
  subScaleId: number;
  title: string;
  configs: {
    configId: number;
    category: {
      type: string;
      name: string;
    };
    isSkippable: boolean;
    isSkipped: boolean;
    questions: {
      questionId: number;
      type: string;
      isMultiAnswer: boolean;
      isSkipped: boolean;
      question: string;
      subjectiveOptions: {
        optionId: number;
        formType: string;
        sortOrder: number;
        attributes: {
          additionalProp1: string;
          additionalProp2: string;
          additionalProp3: string;
        };
        userAnswer: string;
      }[];
      objectiveOptions: {
        optionId: number;
        label: string;
        sortOrder: number;
        value: string;
        selected: boolean;
        userAnswer: string;
        attributes: {
          additionalProp1: string;
          additionalProp2: string;
          additionalProp3: string;
        };
      }[];
      scaleAnswerOption: {
        optionId: number;
        startLabel: string;
        startValue: number;
        endLabel: string;
        endValue: number;
        scaleStep: number;
        attributes: {
          additionalProp1: string;
          additionalProp2: string;
          additionalProp3: string;
        };
        userAnswer: number;
      };
      contents: {
        type: string;
        content: string;
      }[];
      purposes: {
        type: string;
        name: string;
      }[];
      category: {
        type: string;
        name: string;
      };
      factor: string;
      codeFKey: string;
    }[];
    contents: {
      type: string;
      content: string;
    }[];
    nextConfigSurvey: {
      questionId: number;
      optionId: number;
      nextConfigId: number;
    }[];
  }[];
};

export class SurveyAPI extends AxiosConfig {
  private _prefix = '/v1';
  private _url = `${this._prefix}/survey`;

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(useBaseURL?: boolean, token?: string, device?: string) {
    super(useBaseURL, token, device);
  }

  /**
   * 12. 설문 API - 하위요인 저장
   * @see https://chc-app-api.huraydev.net/swagger-ui/index.html
   * @version 1.0.0
   */
  public postSubScaleAsync = async ({ body }: AxiosRequest<any, any, PostSubScaleRequest>): Promise<AxiosResponse<any> | AxiosResponse<unknown, any>> => {
    try {
      return await this.instance.post<any>(`${this._url}/sub-scale`, {
        ...body,
      });
    } catch (error) {
      return axiosErrorExceptionAsync(error);
    }
  };

  /**
   * 12. 설문 API - 측정도구 목록 조회
   * @see https://chc-app-api.huraydev.net/swagger-ui/index.html
   * @version 1.0.0
   */
  public getListAsync = async (): Promise<AxiosResponse<any> | AxiosResponse<unknown, any>> => {
    try {
      return await this.instance.get<any>(`${this._url}`);
    } catch (error) {
      return axiosErrorExceptionAsync(error);
    }
  };

  /**
   * 12. 설문 API - 특정 측정도구의 하위요인 목록 조회
   * @see https://chc-app-api.huraydev.net/swagger-ui/index.html
   * @version 1.0.0
   */
  public getDetailsAsync = async ({ param }: AxiosRequest<SurveyParamRequest>): Promise<AxiosResponse<any> | AxiosResponse<unknown, any>> => {
    try {
      return await this.instance.get<any>(`${this._url}/survey/${param?.surveyId}`);
    } catch (error) {
      return axiosErrorExceptionAsync(error);
    }
  };

  /**
   * 12. 설문 API - 특정 하위요인의 문항 목록 조회
   * @see https://chc-app-api.huraydev.net/swagger-ui/index.html
   * @version 1.0.0
   */
  public getSubScaleAsync = async ({ param }: AxiosRequest<SurveyParamRequest>): Promise<AxiosResponse<any> | AxiosResponse<unknown, any>> => {
    try {
      return await this.instance.get<any>(`${this._url}/${param?.surveyId}/sub-scale/${param?.subScaleId}`);
    } catch (error) {
      return axiosErrorExceptionAsync(error);
    }
  };
}
