import React from 'react';
import { css } from '@emotion/react';
import { SerializedStyles } from '@emotion/serialize';
import Colors from '../../../types/colors';

type Variant = 'primary' | 'secondary' | 'cancel' | 'tab' | 'linear' | 'disable';
type Size = 120 | 56 | 50 | 40 | 38 | 26;

export type ButtonViewProps = {
  rounded?: string;
  handleClick?: () => void;
  variant: Variant;
  text: any;
  width?: string;
  size?: Size;
  cssStyles?: SerializedStyles;
  isMore?: boolean;
  type?: string;
  fontSize?: number;
} & Pick<React.ComponentProps<'button'>, 'onClick' | 'disabled' | 'value'>;

const ButtonView = ({ rounded, handleClick, variant, size = 40, fontSize = 16, width = '100%', text, cssStyles, isMore, disabled, type, ...rest }: ButtonViewProps): JSX.Element => {
  const variantStyles: Record<Variant, SerializedStyles> = {
    primary: css`
      background-color: ${Colors['Primary_100_basic']};
      color: ${Colors['white_regular']};

      &:disabled {
        background-color: ${Colors['Grey_01_bg']};
        color: ${Colors['Grey_03_regular']};
      }
    `,
    secondary: css`
      background-color: ${Colors['Secondary_100_basic']};
      color: ${Colors['Primary_100_basic']};
      &:disabled {
        background-color: ${Colors['Grey_01_bg']};
        color: ${Colors['Grey_03_regular']};
      }
    `,

    cancel: css`
      background-color: ${Colors['Grey_03_regular']};
      color: ${Colors['white_regular']};
      &:disabled {
        background-color: ${Colors['Grey_03_regular']};
        color: ${Colors['white_regular']};
      }
    `,

    tab: css`
      background-color: ${Colors['white_regular']};
      color: black;
      border: 1px solid ${Colors['Black_basic']};

      svg {
        margin-left: 8px;
      }

      &:disabled {
        color: gray;
        border: 1px solid ${Colors['Grey_02_line']};
      }
    `,

    linear: css`
      background-color: ${Colors['white_regular']};
      color: ${Colors['Black_basic']};

      //border-bottom: 1px solid black;
    `,
    disable: css`
      background-color: ${Colors['Grey_02_line']};
      color: ${Colors['Grey_03_regular']};
    `,
  };
  const sizeStyles: Record<Size, SerializedStyles> = {
    26: css`
      font-family: var(--normal);
      height: 20px;
      font-weight: 400;

      line-height: 168%;
    `,
    38: css`
      font-family: var(--normal);
      height: 40px;
      font-weight: 400;

      line-height: 17px;
    `,
    40: css`
      font-family: var(--bold);
      height: 30px;

      line-height: 168%;
    `,
    50: css`
      font-family: var(--bold);
      height: 38px;

      line-height: 18px;
    `,
    56: css`
      font-family: var(--bold);
      height: 56px;

      line-height: 164%;
    `,
    120: css`
      font-family: var(--bold);
      height: 48px;

      line-height: 164%;
    `,
  };
  const buttonStyle = css`
    border: 0;
    border-radius: ${rounded ? `${rounded}` : '10px'};
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${width};
    letter-spacing: -0.03em;
    min-height: 20px;
    font-size: ${fontSize}px;
    ${variantStyles[variant]}
    ${size && sizeStyles[size]}
      ${{ ...cssStyles }}
  `;
  return (
    <>
      <button disabled={disabled} css={buttonStyle} onClick={handleClick}>
        {text}
      </button>
    </>
  );
};

export default ButtonView;
