import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { AlarmAPI } from '../../../apis/v1/alarm.api';
import SurveyInfoSectionView from './survey-info-section.view';
import useViewportWidth from '../../../hooks/useViewportWidth';
import { SurveyAPI } from '../../../apis/v1/survey.api';
import useAuthentication from '../../../store/useAuthentication';

const SurveyInfoSection = () => {
  const { authentication } = useAuthentication();
  const alarmApi = new AlarmAPI(process.env.REACT_APP_ENV !== 'dev', authentication.accessToken, authentication.deviceId);
  const surveyApi = new SurveyAPI(process.env.REACT_APP_ENV !== 'dev', authentication.accessToken, authentication.deviceId);
  const [isNewAlarm, setIsNewAlarm] = useState(false);
  const [surveyData, setSurveyData] = useState<any>([]);
  const [progressBars, setProgressBars] = useState<string[]>(Array(surveyData?.dataList?.length).fill('#D9D9D9'));
  const [inCompleteData, setInCompleteData] = useState<any>();
  const navigate = useNavigate();
  const viewportWidth = useViewportWidth();

  useEffect(() => {
    setInCompleteData(surveyData?.dataList?.find((item: { isComplete: false }) => !item.isComplete));
    setProgressBars((prevBars) => surveyData?.dataList?.map((value: { isComplete: boolean }) => (value.isComplete ? '#007aff' : '#D9D9D9')) ?? prevBars);
  }, [surveyData?.dataList]);

  useEffect(() => {
    if (authentication.accessToken && authentication.deviceId) {
      const fetchData = async () => {
        try {
          const { data, status }: any = await alarmApi.getAsync();
          // if (data?.code?.includes('ERR_401')) {
          //   await window.flutter_inappwebview.callHandler('goRefresh', '');
          //   // navigate('/index.html');
          // } else if (data?.code?.includes('ERR_500')) {
          //   await window.flutter_inappwebview.callHandler('goErrorPage', '');
          // } else if (data?.code === 'ERR-406-007') {
          //   await window.flutter_inappwebview.callHandler('goRefresh', '');
          // } else if (data.message === 'ERR_400_000') {
          //   await window.flutter_inappwebview.callHandler('showToastMessage', '요청인자가 유효하지 않습니다.');
          //   return;
          // } else if (data?.code) {
          //   await window.flutter_inappwebview.callHandler('showToastMessage', `${data?.message}`);
          //   return;
          // }
          for (let message of data?.messageList) {
            if (!message.isRead) {
              setIsNewAlarm(true);
              break;
            }
          }
          for (let voc of data?.vocList) {
            if (!voc.isRead) {
              setIsNewAlarm(true);
              break;
            }
          }
        } catch (error) {
          // await window.flutter_inappwebview.callHandler('goErrorPage', '');
        }
      };
      fetchData();
    }
  }, [authentication.accessToken, authentication.deviceId]);

  useEffect(() => {
    if (authentication.accessToken && authentication.deviceId) {
      const fetchData = async () => {
        try {
          const { data, status }: any = await surveyApi.getListAsync();
          // if (data?.code?.includes('ERR_401')) {
          //   await window.flutter_inappwebview.callHandler('goRefresh', '');
          //   // navigate('/index.html');
          // } else if (data?.code?.includes('ERR_500')) {
          //   await window.flutter_inappwebview.callHandler('goErrorPage', '');
          // } else if (data?.code === 'ERR-406-007') {
          //   await window.flutter_inappwebview.callHandler('goRefresh', '');
          // } else if (data.code === 'ERR_400_000') {
          //   await window.flutter_inappwebview.callHandler('showToastMessage', '요청인자가 유효하지 않습니다.');
          //   return;
          // }
          setSurveyData(data);
        } catch (error) {
          // await window.flutter_inappwebview.callHandler('goErrorPage', '');
        }
      };
      fetchData();
    }
  }, [authentication.accessToken, authentication.deviceId]);
  const handleSurveyBtn = async () => {
    if (process.env.REACT_APP_BUILD === 'flutter') {
      const response = await window.flutter_inappwebview.callHandler('showReSurvey');
      if (response) {
        // @ts-ignore
        await window.flutter_inappwebview.callHandler('openSurvey', inCompleteData.surveyId);
      }
    } else {
      navigate('/survey');
    }
  };
  const handleInCompleteData = async () => {
    if (process.env.REACT_APP_BUILD === 'flutter') {
      const response = await window.flutter_inappwebview.callHandler('showReSurvey');
      if (response) {
        // @ts-ignore
        await window.flutter_inappwebview.callHandler('openContinueSurvey', inCompleteData.surveyId);
      }
    } else {
      navigate(`https://chc-app-api.huraydev.net/v1/survey/${inCompleteData.surveyId}`);
    }
  };
  const props = {
    handleSurveyBtn,
    isNewAlarm,
    viewportWidth,
    progressBars,
    surveyData,
    handleInCompleteData,
  };

  return <SurveyInfoSectionView {...props} />;
};

export default SurveyInfoSection;
