import TypographyView from '../../../../atoms/foundation/typography.view';
import { css } from '@emotion/react';
import HeadView from '../../../../molecules/common/head.view';
import Icon from '../../../../atoms/foundation/icon.view';
import BackArrow from '../../../../../assets/images/svg/icons_arrow_back.svg';
import MESSAGES from '../../../../../constants/messages';
import React from 'react';
import convertDate from '../../../../../utils/dateConverter';
import NoMessageView from '../../../../atoms/alert/no-message.view';
import { ImageEmptyMessage } from '../../../../../assets/images/svg';
import LoadingSpinner from '../../../../atoms/foundation/loading-spinner.view';
import useDpi from '../../../../../hooks/useDpi';

interface SelectedMessageViewProps {
  handleGoBack: () => void;
  selectedMessage: { messageContent: string; messageAt: string };
  isLoading: boolean;
}
const SelectedMessageView = ({ handleGoBack, selectedMessage, isLoading }: SelectedMessageViewProps) => {
  const { subFontSize, miniFontSize, miniIcons } = useDpi();

  const wrapper = css`
    width: 100%;
    height: 100%;
    background-color: white;
    & > *:not(irst-of-type) {
      padding: 0 20px;
    }
    padding-top: 50px;
  `;
  const typography = css`
    margin-top: 20px;
  `;
  const spinnerWrapper = css`
    display: flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    align-items: center;
    margin-top: 130px;
  `;
  return (
    <div css={wrapper}>
      <>
        <HeadView handleClick={handleGoBack} icon={<Icon src={BackArrow} width={`${miniIcons}px`} height={`${miniIcons}px`} />} header={MESSAGES.HEAD_TITLES.MESSAGE} />
        {isLoading ? (
          <div css={spinnerWrapper}>
            <LoadingSpinner />
          </div>
        ) : (
          <>
            {!selectedMessage && (
              <>
                <HeadView handleClick={handleGoBack} icon={<Icon src={BackArrow} width={`${miniIcons}px`} height={`${miniIcons}px`} />} header={MESSAGES.HEAD_TITLES.MESSAGE} />
                <NoMessageView icon={ImageEmptyMessage} text={MESSAGES.INFORMATION.EMPTY_MESSAGE} height={'95vh'} />
              </>
            )}
            <TypographyView cssStyles={typography} gap={20} color={'Grey_03_regular'} fontSize={miniFontSize} bold={true}>
              {convertDate(selectedMessage?.messageAt)}
            </TypographyView>
            <TypographyView fontSize={subFontSize} color={'Grey_05_subtext'}>
              {selectedMessage?.messageContent}
            </TypographyView>
          </>
        )}
      </>
    </div>
  );
};

export default SelectedMessageView;
