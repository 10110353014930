const Colors = {
  Primary_100_basic: '#5aa0ff',
  Secondary_100_basic: '#ffffff',
  white_regular: '#ffffff',
  Black_basic: '#1e1e1e',
  Grey_01_bg: '#f5f5f5',
  Grey_02_line: '#ebebeb',
  Grey_03_regular: '#aaaaac',
  Grey_04_inactive: '#828284',
  Grey_05_subtext: '#505052',
  Yellow_bar: '#ffd74b',
  Red_bar: '#ff375e',
  Red_regular: '#ff375e',
  Red_light: '#ffebee',
  Green_bar: '#37dc8c',
  Blue_light: '#eef5ff',
  Blue_regular: '#5aa0ff'

};
export default Colors;
