import React from 'react';
import { css, keyframes } from '@emotion/react';
import Colors from '../../../types/colors';

const wrapper = css`
  z-index: 9999;
`;

const spinner = css`
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
`;

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const circle = css`
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 4px solid ${Colors['Grey_03_regular']};
  border-radius: 50%;
  animation: ${rotate} 2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: ${Colors['Grey_03_regular']} transparent transparent transparent;
`;

const LoadingSpinner = () => (
  <div css={wrapper}>
    <div css={spinner}>
      <div css={circle}></div>
    </div>
  </div>
);

export default LoadingSpinner;
