import { AxiosResponse } from 'axios';
import { AxiosConfig, axiosErrorExceptionAsync } from '../axios.config';

export type GetResponse = {
  messageList: any[];
  vocList: any[];
};

export class AlarmAPI extends AxiosConfig {
  private _prefix = '/v1';
  private _url = `${this._prefix}/alarm`;

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(useBaseURL?: boolean, token?: string, device?: string) {
    super(useBaseURL, token, device);
  }

  /**
   * 06. 알람 API - 알람 목록 조회
   * @see https://chc-app-api.huraydev.net/swagger-ui/index.html
   * @version 1.0.0
   */
  public getAsync = async (): Promise<AxiosResponse<GetResponse> | AxiosResponse<unknown, any>> => {
    try {
      return await this.instance.get<GetResponse>(`${this._url}`);
    } catch (error) {
      return axiosErrorExceptionAsync(error);
    }
  };
}
