import React from 'react';
import ReportView from '../../organisms/report/report.view';
import { css } from '@emotion/react';
import Colors from '../../../types/colors';

interface ReportPageViewProps {
  reportPageData: any;
}

const ReportPageView = ({ reportPageData }: ReportPageViewProps) => {
  const top = css`
    background-color: ${Colors.white_regular};
    height: 50px;
  `;
  const wrapper = css`
    height: 100%;
  `;

  return (
    <div css={wrapper}>
      <div css={top} />
      <div>
        <ReportView reportPageData={reportPageData} />
      </div>
    </div>
  );
};

export default ReportPageView;
