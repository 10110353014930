import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MoreProfileView from './more-profile.view';
import { EtcApi, GetProfileResponse } from '../../../../apis/v1/etc.api';
import useAuthentication from '../../../../store/useAuthentication';

const MoreProfile = () => {
  const { authentication } = useAuthentication();
  const etcApi = new EtcApi(process.env.REACT_APP_ENV !== 'dev', authentication.accessToken, authentication.deviceId);
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState<GetProfileResponse>();
  useEffect(() => {
    if (authentication.accessToken && authentication.deviceId) {
      const fetchData = async () => {
        try {
          const { data }: any = await etcApi.getProfileAsync();
          setProfileData(data);
        } catch (error) {
          // await window.flutter_inappwebview.callHandler('goErrorPage', '');
        }
      };
      fetchData();
    }
  }, [authentication.accessToken, authentication.deviceId]);

  const handleGoBack = () => {
    navigate('/more');
  };

  const handleCopyButton = async () => {
    if (process.env.REACT_APP_BUILD === 'flutter') {
      await window.flutter_inappwebview.callHandler('flutterCopy', profileData?.certificationCode as string);

      // @ts-ignore
    } else {
      navigator.clipboard.writeText(profileData?.certificationCode as string);
      alert('인증코드가 복사되었습니다');
    }
  };

  const handleLogoutButton = async () => {
    if (process.env.REACT_APP_BUILD === 'flutter') {
      // @ts-ignore
      await window.flutter_inappwebview.callHandler('logout', 'string message');
    } else {
      alert('로그아웃');
    }
  };

  const props = {
    handleCopyButton,
    handleLogoutButton,
    handleGoBack,
    profileData,
  };

  return <MoreProfileView {...props} />;
};

export default MoreProfile;
