import React from 'react';
import HeadView from '../../../../molecules/common/head.view';
import Icon from '../../../../atoms/foundation/icon.view';
import { ArrowBack } from '../../../../../assets/images/svg';
import { css } from '@emotion/react';
import TypographyView from '../../../../atoms/foundation/typography.view';
import useDpi from '../../../../../hooks/useDpi';
interface MoreTermsDetailViewProps {
  handleBackButton: () => void;
  termsData: any;
}

const MoreTermsDetailView = ({ handleBackButton, termsData }: MoreTermsDetailViewProps) => {
  const { miniIcons } = useDpi();
  const container = css`
    width: 100vw;
    height: 100%;
    background-color: white;
    padding-top: 50px;
  `;
  const contentWrapper = css`
    padding: 15px;
  `;

  return (
    <div css={container}>
      <HeadView handleClick={handleBackButton} icon={<Icon src={ArrowBack} width={`${miniIcons}px`} height={`${miniIcons}px`} />} header={termsData?.title} />
      <div css={contentWrapper}>
        <TypographyView bold={true} gap={15} children={termsData?.title} />
        <div dangerouslySetInnerHTML={{ __html: termsData?.content }} /> {/* Render content as HTML */}
      </div>
    </div>
  );
};

export default MoreTermsDetailView;
