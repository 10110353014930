const MESSAGES = {
  INFORMATION: {
    DASHBOARD_INFO: {
      TOP: '차년도',
      BOTTOM: '설문이 완료되지 않았습니다!',
    },
    DASHBOARD_INFO_DO_SURVEY: {
      TOP: '차년도',
      BOTTOM: '설문을 진행해주세요!',
    },
    DASHBOARD_SUB_INFO: {
      TOP: '환아의 건강상태 파악을 위해',
      BOTTOM: '설문이 꼭 필요해요',
    },
    EMPTY_DASHBOARD_DATA: {
      TOP: '아직 리포트가 생성되지 않았습니다.',
      BOTTOM: '설문에 응답하면 리포트가 생성됩니다.',
    },
    EMPTY_GRAPH_DATA: {
      TOP: '해당 리포트가 생성되지 않았습니다.',
      BOTTOM: '설문에 응답하면 리포트가 생성됩니다.',
    },

    EMPTY_MESSAGE: '도착한 메시지가 없습니다.',
    EMPTY_QNA: '질문&답변이 없습니다.',
    KEY_REPORT: '님의 주요 리포트',
    BAR_INFO_GOOD: '좋음',
    BAR_INFO_NORMAL: '중간',
    BAR_INFO_BAD: '나쁨',
    WITHDRAWAL_FUNC: '※ 탈퇴기능은 지원하지 않습니다.',
    CONTACT_ADMIN: '서비스 탈퇴를 원하실 경우 관리자에게 문의하세요.',
    PHONE_NUMBER_INFO: '환아 : 휴대폰번호가 없는 경우 보호자 휴대폰번호로 인증해주세요.',
    PW_VALIDATION_INFO: '- 연속적인 숫자나 생일과 아이디를 포함한 추정가능한 비밀번호는 사용하지 마세요.',
    MESSAGE_AVAILABLE_INFO: '※ 메시지 수신일로부터 30일 후 목록에서 삭제됩니다.',
    SURVEY_COMPLETE: '차년도 설문이 완료되었습니다!',
    ERROR_INFO: '일시적인 오류입니다',
    ERROR_INFO_SUB: {
      TOP: '이용에 불편을 드려 죄송합니다.',
      BOTTOM: '잠시 후 다시 시도해 주세요.',
    },
    VERSION: 'v 0.1.0',
    MEMBERSHIP_INFO: '보호자 회원가입 후 인증코드가 생성됩니다.\n' + '환아는 만 11세 경과 시점부터 인증코드를 이용해 \n' + '회원가입이 가능합니다.',
    PARENTAL_INFO:
      '※ 환아 정보를 미리 적는 이유는 무엇인가요? \n' +
      '\n' +
      '사전가입 등록된 내용과 일치하는 지 확인하는 절차입니다. 또한, 보호자님께서 가입 후에 환아인증코드를 발급받는데 목적이 있습니다.\n' +
      '\n' +
      '보호자님께서 우선 가입하시고, 환아는 발급된 인증코드를 이용해 만 11세 이상 시점 때 회원가입 및 설문 응답이 가능합니다. ',
  },
  PLACEHOLDER: {
    ENTER_ID: '아이디를 입력해주세요',
    ENTER_PW: '비밀번호를 입력해주세요',
    INPUT: '입력',
    PHONE_NUMBER: "'-'없이 입력",
    VALID_ID: '영문+숫자, 6~12자',
    VALID_PW: '영문+숫자, 8~12자',
    PW_CHECK: '비밀번호 확인',
    CURR_PW: '현재 비밀번호',
    NEW_PW: '새 비밀번호 (영문+숫자, 8~16자)',
    CONFIRM_NEW_PW: '새 비밀번호 확인',
    CHOICE: '선택',
    MALE: 'M',
    FEMALE: 'F',
    NEEDS_ADMIN_ANSWER: '관리자 답변 대기중 입니다.',
    NO_QUESTION: '질문을 찾을 수 없습니다.',
  },
  ALERT_MESSAGE: {
    ENTER_NAME: '이름을 입력해주세요',
    SELECT_BIRTHDATE: '생년월일을 선택해주세요',
    SELECT_GENDER: '성별을 선택해주세요',
    ENTER_VALID_PHONENUMBER: '올바른 휴대폰번호 형식이 아닙니다.',
    ENTER_AUTH_CODE: '인증문자 6자리를 입력해주세요',
    MEMBERSHIP_SIGN_UP_INFO: '보호자&환아 정보가 모두 일치해야 가입이 가능합니다. 반복적으로 진행이 불가한 경우 관리자에게 문의해주세요. ',
    ACCOUNT_VERIFICATION_FAILED: '계정확인에 실패하였습니다. \n' + '입력한 계정 정보가 정확한 지 확인해주세요.',
    PARENT_CHILD_INFO_MATCH_FAILED: '보호자&환아 정보가 모두 일치해야 인증코드 확인이 가능합니다. 반복적으로 진행이 불가한 경우 관리자에게 문의해주세요. ',
  },
  ERROR_MESSAGE: {
    CURR_PW: '현재 비밀번호가 일치하지 않습니다.',
    VALID_PW: '영문+숫자 혼합 8~16자로 입력해주세요.',
    NEW_PW_NOT_MATCH: '새 비밀번호가 일치하지 않습니다. 다시 입력해 주세요.',
    VALID_PHONE_NUMBER: '올바른 휴대폰번호 형식이 아닙니다.',
    VALID_ENGINE_NUMBER: '유효한 엔진 번호가 아닙니다.',
    ENTER_NAME: '이름을 입력해주세요.',
    ENTER_BIRTHDATE: '생년월일을 선택해주세요.',
    CHOOSE_GENDER: '성별을 선택해주세요.',
  },
  CONFIRM_MESSAGE: {
    STAY_OR_GO_TO_LOGIN_PAGE: '뒤로가기 시, 로그인 화면으로 이동되며 \n' + '입력했던 정보가 모두 사라집니다. \n' + '현재 화면에서 계속 머무르시겠습니까?\n',
  },
  BUTTON: {
    LOGIN: '로그인',
    JOIN_MEMBERSHIP: '회원가입',
    SEARCH_ID: '회원가입',
    RESET_PW: '비밀번호 재설정',
    NEXT: '다음',
    PREV: '이전',
    SEE: '보기',
    AUTH_REQUEST: '인증요청',
    CERTIFY: '인증',
    VERIFIED: '인증완료',
    CHECK: '확인',
    MOVE_LOGIN_SCREEN: '로그인 화면 이동',
    STAY: '머무르기',
    CANCEL: '취소',
    HOME: '홈',
    SURVEY: '설문',
    REPORT: '리포트',
    SEE_MORE: '더보기',
    DO_SURVEY: '설문하기',
    CONNECTING: '이어하기',
    ALL: '전체',
    SLEEP: '수면',
    FATIGUE: '피로',
    PHYSICAL: '신체적 기능',
    SUB_SCALE1: 'Sub-Scale 1',
    SUB_SCALE2: 'Sub-Scale 2',
    DONE: '완료',
    YES: '예',
    NO: '아니오',
    LOGOUT: '로그아웃',
    FIND_PW: '현재 비밀번호를 잊으셨나요?',
    EDIT: '저장',
    SHOW_ALL: '전체보기',
    UNREAD: '안읽음',
    AKS_QUESTION: '질문하기',
    REGISTER: '등록',
    DELETE: '삭제',
    GO_BACK: '이전으로 돌아가기',
  },
  RADIO_BUTTON: {
    MALE: '남자',
    FEMALE: '여자',
  },
  AGREEMENTS: {
    CONDITIONS_AND_TERMS: '약관에 전체동의',
    SERVICE_USE_TERMS: '(필수) 서비스이용약관',
    PERSONAL_INFO_POLICY: '(필수) 개인정보처리방침',
    SENSITIVE_INFO: '(필수) 민감정보',
  },
  FORM_TITLES: {
    NAME: '이름',
    BIRTHDATE: '생년월일',
    GENDER: '성별',
    PHONE_NUMBER: '휴대폰번호',
    AUTH_CODE: '인증번호',
    ID: '아이디',
    PW: '비밀번호',
    PW_CHECK: '비밀번호 확인 ',
    PARENT_CELLPHONE: '보호자 휴대폰번호 ',
    AUTH_MESSAGE: '인증문자',
  },
  ICON_NAME: {
    SLEEP: '수면',
    FATIGUE: '피로',
    PHYSICAL: '신체적 기능',
  },
  HEAD_TITLES: {
    ALARM: '알림',
    MESSAGE: '메시지',
    QNA: '질문&답변',
    CLIENT: '고객님',
    PROFILE: '프로필',
    TERM_AND_CONDITION: '약관정보',
    PW_CHANGE: '비밀번호 변경',
    ENGINE_CODE: '본인인증',
    REPORT: '리포트',
    NEW_PW: '비밀번호 재설정',
  },
  ROW_TITLES: {
    MESSAGE: '메시지',
    QNA: '질문&답변',
    RECEIVE: '푸시 수신 설정',
    TERM_AND_CONDITION: '약관정보',
    VERSION: '(버전)',
    ACC_INFO: '계정정보',
    ID: '아이디',
    PW_CHANGE: '비밀번호 변경',
    USER_INFO: '사용자 정보',
    NAME: '이름',
    BIRTHDATE: '생년월일',
    SEX: '성별',
    ENGINE_CODE: '인증코드',
    ENGINE_NUMBER: '인증번호',
    CHILD_NAME: '환아 이름',
    CHILD_BIRTHDATE: '환아 생년월일',
    PARENT_NAME: '보호자 이름',
    PARENT_BIRTHDATE: '보호자 생년월일',
    FAMILY_INFO: '가족 정보',
    PATIENT_SEX: '환아 성별',
    RESPONSIBLE_MANAGER: '담당 관리자',
    SELF_PHONE_NUMBER: '휴대폰번호',
    PHONE_NUMBER: '전화번호',
    ADMIN_ANSWER: '관리자 답변',
    PHYSICAL_HEALTH: '신체 건강',
    MENTAL_HEALTH: '정신 건강',
    COGNITIVE_FUNC: '인지 기능',
    SOCIAL_HEALTH: '사회적 건강',
    TERMS_AND_CONDITIONS: {
      TERMS_OF_SERVICE: '서비스 이용약관',
      PRIVACY_POLICY: '개인정보처리방침',
      SENSITIVE_INFO: '민감정보',
    },
  },
};

export default MESSAGES;
