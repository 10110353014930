import React, { ElementType, ReactNode } from 'react';
import { css, jsx } from '@emotion/react';
import { SerializedStyles } from '@emotion/serialize';
import Colors from '../../../types/colors';

export type TypographyViewProps = {
  children: ReactNode | string;
  component?: ElementType<any>;
  fontSize?: number;
  bold?: boolean;
  color?: keyof typeof Colors;
  align?: 'left' | 'center' | 'right' | 'inherit';
  gap?: number;
  cssStyles?: SerializedStyles;
};

const TypographyView = ({ component = 'div', children, fontSize = 16, gap = 0, bold = false, color, align = 'inherit', cssStyles }: TypographyViewProps) => {
  const TypographyStyles = css`
    //width: max-content;
    font-family: var(--normal);
    color: ${color ? Colors[color] : 'inherit'};
    text-align: ${align};
    margin-bottom: ${gap}px;
    font-weight: ${bold ? 600 : 400};
    letter-spacing: -0.03em;
    font-size: ${fontSize}px;

    ${{ ...cssStyles }};
  `;
  return jsx(component, { css: TypographyStyles }, children);
};
export default TypographyView;
