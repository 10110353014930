import React, { ReactNode } from 'react';
import TypographyView from '../../atoms/foundation/typography.view';
import { css } from '@emotion/react';
import Colors from '../../../types/colors';

interface RowListViewProps {
  handleClick?: () => void;
  title: ReactNode;
  isValue?: boolean;
  update?: boolean;
  value?: any;
  color?: keyof typeof Colors;
  cssStyle?: any;
}

const RowListView = ({ handleClick, title, update, isValue, value, color, cssStyle }: RowListViewProps) => {
  const row = css`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${Colors['Grey_02_line']};
    background-color: ${Colors['white_regular']};
    color: ${Colors['Grey_05_subtext']};
    align-items: center;
    width: 100vw;
  `;

  const typography = css`
    display: flex;
    align-items: center;
    ${{ ...cssStyle }};
  `;

  if (update)
    return (
      <>
        <div css={row}>
          {' '}
          <div onClick={handleClick}>
            <TypographyView bold={true} color={color ? color : 'Grey_05_subtext'} cssStyles={typography}>
              {title}
            </TypographyView>
          </div>
          {value}
        </div>
      </>
    );

  if (isValue)
    return (
      <div css={row}>
        <TypographyView cssStyles={typography} bold={true} color={color ?? 'Grey_05_subtext'}>
          {title}
        </TypographyView>
        <span>{value}</span>
      </div>
    );
  else
    return (
      <div css={row} onClick={handleClick}>
        <TypographyView cssStyles={typography} bold={true} color={color ? color : 'Grey_05_subtext'}>
          {title}
        </TypographyView>
      </div>
    );
};

export default RowListView;
