import React, { ReactElement } from 'react';
import TypographyView from '../../atoms/foundation/typography.view';
import { css } from '@emotion/react';
import Colors from '../../../types/colors';
import ButtonView from '../../atoms/inputs/button.view';
import MESSAGES from '../../../constants/messages';
import useDpi from '../../../hooks/useDpi';

interface HeadViewProps {
  handleClick: () => void;
  icon: ReactElement<any, any>;
  header: string | undefined;
  handleAction?: () => void;
  isActionAdd?: boolean;
  disable?: boolean;
  isDelete?: boolean;
}

const HeadView = ({ disable, handleClick, icon, isDelete, header, handleAction, isActionAdd }: HeadViewProps) => {
  const { listTitle, subFontSize } = useDpi();
  const head = css`
    position: relative;
    display: flex;
    height: 56px;
    align-items: center;
    background-color: ${Colors['white_regular']};
    border-bottom: ${handleAction ? 'none' : `1px solid ${Colors['Grey_02_line']}`};

    div {
      margin: 0 auto;
    }
  `;
  const backBtn = css`
    padding-left: 21px;
    border: none;
    background-color: transparent;
  `;
  const button = css`
    position: absolute;
    color: ${!isActionAdd && Colors['Red_regular']};
    width: ${isDelete ? 'fit-content' : '75px'};
    height: 40px;
    font-size: ${subFontSize}px;
    margin-right: 21px;
    right: 0;
  `;
  const typography = css`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
  `;
  return (
    <div css={head}>
      <button css={backBtn} onClick={handleClick}>
        {icon}
      </button>
      <TypographyView cssStyles={typography} fontSize={listTitle}>
        {header}
      </TypographyView>
      {handleAction && (
        <ButtonView
          disabled={disable}
          cssStyles={button}
          rounded={'15px'}
          handleClick={handleAction}
          size={40}
          variant={isActionAdd ? 'primary' : 'secondary'}
          text={isActionAdd ? MESSAGES.BUTTON.REGISTER : MESSAGES.BUTTON.DELETE}
        />
      )}
    </div>
  );
};

export default HeadView;
