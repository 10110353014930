import React from 'react';
import { css } from '@emotion/react';
import Colors from '../../../types/colors';
import ButtonView from '../../atoms/inputs/button.view';
import MESSAGES from '../../../constants/messages';

import RowListView from '../common/row-list.view';
import useDpi from '../../../hooks/useDpi';

interface ListItem {
  handleClick: (index: number) => void;
  lastDisabledIndex: number;
  surveyPageData: any;
}

const SurveyBodySectionView = ({ lastDisabledIndex, handleClick, surveyPageData }: ListItem): JSX.Element => {
  const { subFontSize, listTitle } = useDpi();
  const wrapper = css`
    display: flex;
    flex-direction: column;
    background-color: ${Colors['white_regular']};
    border-top: 1px solid ${Colors['Grey_02_line']};
  `;

  const title = css`
    display: flex;
    font-size: ${listTitle}px;
    height: 66px;
    padding: 0 21px;
  `;
  const disableTitle = css`
    opacity: 0.5;
    display: flex;
    font-size: ${listTitle}px;
    height: 66px;
    padding: 0 21px;
  `;

  const buttonStyle = css`
    height: 40px;
    width: 86px;
    margin-right: 21px;
  `;

  return (
    <div css={wrapper}>
      {surveyPageData?.dataList?.map((value: { isComplete: boolean; surveyId: number; surveyName: string }, index: number) => {
        const prevIsComplete = surveyPageData.dataList[index - 1]?.isComplete;
        const isDisabled = prevIsComplete === false || index <= lastDisabledIndex;
        const buttonText = value.isComplete ? MESSAGES.BUTTON.DONE : MESSAGES.BUTTON.CONNECTING;
        const buttonVariant = value.isComplete ? 'disable' : 'primary';
        const titleStyle = value.isComplete ? disableTitle : title;
        return (
          <RowListView
            key={value.surveyId}
            title={`${index + 1}. ${value.surveyName}`}
            isValue={!isDisabled}
            cssStyle={titleStyle}
            value={
              <ButtonView
                variant={buttonVariant}
                cssStyles={buttonStyle}
                rounded={'15px'}
                fontSize={subFontSize}
                text={buttonText}
                handleClick={() => handleClick(value.surveyId)}
                disabled={value.isComplete}
              />
            }
          />
        );
      })}
    </div>
  );
};

export default SurveyBodySectionView;
