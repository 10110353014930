import React from 'react';
import MoreHeadView from '../../molecules/more/more-head.view';
import { Link } from 'react-router-dom';
import RowListView from '../../molecules/common/row-list.view';
import TypographyView from '../../atoms/foundation/typography.view';
import { css } from '@emotion/react';
import Colors from '../../../types/colors';
import Icon from '../../atoms/foundation/icon.view';
import ForwardArrow from '../../../assets/images/svg/icons_arrow_next.svg';
import ToggleButton from '../../atoms/inputs/toggle.view';
import MESSAGES from '../../../constants/messages';
import useDpi from '../../../hooks/useDpi';
import useAuthentication from '../../../store/useAuthentication';
import ButtonView from '../../atoms/inputs/button.view';

interface MoreViewProps {
  handlePortfolioPageLink: () => void;
  handleToggleBtn: () => void;
  toggleButton: boolean;
  profileData: any;
  handleUpdateButton?: () => void;
  isNewVersion?: boolean;
}

const MoreView = ({ handlePortfolioPageLink, isNewVersion, handleUpdateButton, handleToggleBtn, toggleButton, profileData }: MoreViewProps) => {
  const { subFontSize, miniIcons, listTitle } = useDpi();
  const { authentication } = useAuthentication();

  const wrapper = css`
    display: flex;
    flex-direction: column;
    background-color: ${Colors['Grey_01_bg']};
    height: calc(100vh - 50px);
  `;

  const typographyWrapper = css`
    padding: 26px 21px;
    background-color: ${Colors['Grey_01_bg']};
  `;
  const link = css`
    text-decoration: none;
  `;

  const typographyStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: center;
    font-size: ${listTitle}px;
    height: 66px;
    padding-left: 21px;
    font-weight: 500;
  `;

  const versionWrapper = css`
    position: relative;
    display: flex;
  `;

  const buttonStyle = css`
    position: absolute;
    margin-right: 21px;
    margin-left: 15px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    white-space: nowrap;
  `;

  const version = css`
    padding-right: ${isNewVersion ? '110px' : '21px'};
  `;

  return (
    <div css={wrapper}>
      {profileData && <MoreHeadView handleClick={handlePortfolioPageLink} icon={<Icon src={ForwardArrow} height={`${miniIcons}px`} width={`${miniIcons}px`} />} username={profileData?.loginName} />}
      <>
        <Link css={link} to={'/more/messages'}>
          <RowListView cssStyle={typographyStyle} title={MESSAGES.ROW_TITLES.MESSAGE} color={'Grey_05_subtext'} />
        </Link>
        <Link css={link} to={'/more/question-answer'}>
          <RowListView cssStyle={typographyStyle} title={MESSAGES.ROW_TITLES.QNA} color={'Grey_05_subtext'} />
        </Link>
        <RowListView
          cssStyle={typographyStyle}
          title={MESSAGES.ROW_TITLES.RECEIVE}
          color={'Grey_05_subtext'}
          value={<ToggleButton handleToggleBtn={handleToggleBtn} toggleButton={toggleButton} />}
          isValue={true}
        />
        <Link css={link} to={'/more/terms'}>
          <RowListView cssStyle={typographyStyle} title={MESSAGES.ROW_TITLES.TERM_AND_CONDITION} color={'Grey_05_subtext'} />
        </Link>
        <div css={versionWrapper}>
          <Link css={link} to={'/more/version'}>
            <RowListView
              cssStyle={typographyStyle}
              title={`아이드림${MESSAGES.ROW_TITLES.VERSION}`}
              color={'Grey_05_subtext'}
              value={
                <TypographyView cssStyles={version} fontSize={listTitle}>
                  {authentication.appVersion}
                </TypographyView>
              }
              update={true}
            />
          </Link>
          {isNewVersion && (
            <ButtonView handleClick={handleUpdateButton} cssStyles={buttonStyle} size={38} fontSize={subFontSize} rounded={'15px'} variant={'primary'} text={'업데이트'} width={'75px'} />
          )}
        </div>
        <div style={{ position: 'relative' }}>
          <div css={typographyWrapper}>
            <TypographyView bold={true} gap={6.7} color={'Grey_05_subtext'} fontSize={subFontSize}>
              {MESSAGES.INFORMATION.WITHDRAWAL_FUNC}
            </TypographyView>
            <TypographyView color={'Grey_05_subtext'} fontSize={subFontSize}>
              {MESSAGES.INFORMATION.CONTACT_ADMIN}
            </TypographyView>
          </div>
        </div>
      </>
    </div>
  );
};

export default MoreView;
