import React from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import Colors from '../../../../types/colors';
import RowListView from '../../../molecules/common/row-list.view';
import ButtonView from '../../../atoms/inputs/button.view';
import HeadView from '../../../molecules/common/head.view';
import BackArrow from '../../../../assets/images/svg/icons_arrow_back.svg';
import Icon from '../../../atoms/foundation/icon.view';
import MESSAGES from '../../../../constants/messages';
import convertDate from '../../../../utils/dateConverter';
import LoadingSpinner from '../../../atoms/foundation/loading-spinner.view';
import useDpi from '../../../../hooks/useDpi';
import NoMessageView from '../../../atoms/alert/no-message.view';
import { ImageEmptyQnA } from '../../../../assets/images/svg';

interface MoreQuestionAnswerViewProps {
  handleGoBack: () => void;
  location: any;
  handleAskQuestionBtn: () => void;
  qnaData: any;
  isLoading: boolean;
}

const MoreQuestionAnswerListView = ({ handleGoBack, qnaData, location, handleAskQuestionBtn, isLoading }: MoreQuestionAnswerViewProps) => {
  const { miniFontSize, subFontSize, secondaryFontSize, miniIcons, listTitle } = useDpi();
  const top = css`
    overflow: hidden;
    position: fixed;
    top: 0;
    height: 50px;
    width: 100%;
    z-index: 1;
    background-color: ${Colors['white_regular']};
  `;
  const wrapper = css`
    display: flex;
    flex-direction: column;
    height: calc(100vh - 100px);
    width: 100%;
    background-color: ${Colors['Grey_01_bg']};
    padding-top: 50px;
  `;
  const headWrapper = css`
    //position: fixed;
    z-index: 1;
    top: 50px;
    width: 100%;
    background-color: ${Colors['white_regular']};
  `;
  const questionWrapper = css`
    // margin-top: 30px;
  `;
  const isQuestionAnswered = css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    font-size: ${subFontSize}px;
    height: 26px;
    width: 35px;
    border-radius: 15px;
    margin-right: 21px;
  `;

  const subRowListTitle = css`
    height: 66px;
    padding-left: 21px;
    font-size: ${listTitle}px;
    font-weight: 500;
    //padding-top: 10px;
  `;

  const date = css`
    color: ${Colors['Grey_03_regular']};
    font-size: ${miniFontSize}px;
  `;
  const button = css`
    position: fixed;
    bottom: 0;
    font-size: ${secondaryFontSize}px;
  `;
  const spinnerWrapper = css`
    display: flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    align-items: center;
    margin-top: 135px;
  `;
  return (
    <div css={wrapper}>
      <div css={top}></div>

      <div css={headWrapper}>
        <HeadView handleClick={handleGoBack} icon={<Icon src={BackArrow} width={`${miniIcons}px`} height={`${miniIcons}px`} />} header={MESSAGES.HEAD_TITLES.QNA} />
      </div>
      {isLoading ? (
        <div css={spinnerWrapper}>
          <LoadingSpinner />
        </div>
      ) : (
        <div css={questionWrapper}>
          {qnaData?.dataList?.length !== 0 ? (
            qnaData?.dataList?.map((question: { vocId: number; regAt: string; vocTitle: string; isAnswered: string }) => (
              <Link key={question.vocId} to={`${location.pathname}/${question.vocId}`} style={{ textDecoration: 'none' }}>
                <RowListView
                  cssStyle={subRowListTitle}
                  title={
                    <div>
                      <span css={date}>{convertDate(question.regAt)}</span>
                      <br />
                      {question.vocTitle}
                    </div>
                  }
                  isValue={true}
                  value={
                    <div
                      style={{
                        backgroundColor: question.isAnswered === 'Y' ? `${Colors['Blue_light']}` : `${Colors['Red_light']}`,
                        color: question.isAnswered === 'Y' ? `${Colors['Blue_regular']}` : `${Colors['Red_regular']}`,
                      }}
                      css={isQuestionAnswered}>
                      {question.isAnswered === 'Y' ? '답변' : '대기'}
                    </div>
                  }
                />
              </Link>
            ))
          ) : (
            <NoMessageView icon={ImageEmptyQnA} text={MESSAGES.INFORMATION.EMPTY_QNA} height={'83vh'} />
          )}
        </div>
      )}
      <ButtonView handleClick={handleAskQuestionBtn} cssStyles={button} variant={'primary'} text={MESSAGES.BUTTON.AKS_QUESTION} rounded={'0'} size={56} />
    </div>
  );
};

export default MoreQuestionAnswerListView;
