import React from 'react';
import SurveyView from './survey.view';

interface SurveyProps {
  surveyPageData: any;
}

const Survey = ({ surveyPageData }: SurveyProps) => {
  return <SurveyView surveyPageData={surveyPageData} />;
};

export default Survey;
