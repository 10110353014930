import React from 'react';
import { css } from '@emotion/react';

interface ProcessFormProps {
  progressBars?: any;
}

function ProcessFormView({ progressBars }: ProcessFormProps) {
  const progressBarContainer = css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  `;

  const progressBar = css`
    width: 100%;
    height: 7px;
    border-radius: 3px;
  `;
  return (
    <div css={progressBarContainer}>
      {progressBars.map((color: string, index: React.Key | null | undefined) => (
        <div key={index} css={progressBar} style={{ backgroundColor: color }}></div>
      ))}
    </div>
  );
}

export default ProcessFormView;
