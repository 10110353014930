import React, { ChangeEvent } from 'react';
import TextFieldView from '../../../../atoms/inputs/text-field.view';
import TypographyView from '../../../../atoms/foundation/typography.view';
import ButtonView from '../../../../atoms/inputs/button.view';
import { css } from '@emotion/react';
import Colors from '../../../../../types/colors';
import HeadView from '../../../../molecules/common/head.view';
import BackArrow from '../../../../../assets/images/svg/icons_arrow_back.svg';
import Icon from '../../../../atoms/foundation/icon.view';
import MESSAGES from '../../../../../constants/messages';
import HelperTextView from '../../../../atoms/foundation/helper-text.view';
import useDpi from '../../../../../hooks/useDpi';

interface ChangePasswordViewProps {
  handleGoBack: () => void;
  handleSearchPwBtn: () => void;
  handlePwChangeButton: () => void;
  disabled: boolean;
  curPwNumber: { onChange: (event: ChangeEvent<HTMLInputElement>) => void; maxLength: number };
  newPwNumber: { onChange: (event: ChangeEvent<HTMLInputElement>) => void; maxLength: number };
  confirmPwNumber: { onChange: (event: ChangeEvent<HTMLInputElement>) => void; maxLength: number };
  curPwHelperText: string;
  newHelperText: string;
  confirmHelperText: string;
  onForm?: any;
}

const ProfileChangePasswordView = ({
  curPwNumber,
  handleGoBack,
  handleSearchPwBtn,
  handlePwChangeButton,
  disabled,
  newPwNumber,
  confirmPwNumber,
  curPwHelperText,
  newHelperText,
  confirmHelperText,
}: ChangePasswordViewProps) => {
  const { miniFontSize, miniIcons, subFontSize } = useDpi();

  const top = css`
    height: 50px;
    background-color: ${Colors.white_regular};
  `;
  const wrapper = css`
    display: flex;
    flex-direction: column;
    background-color: ${Colors.Grey_01_bg};
    height: 100vh;
  `;

  const password = css`
    display: flex;
    align-items: center;
    border-bottom: none;
    color: ${Colors['Black_basic']};
    width: calc(100vw - 25px);
    height: 63px;
    border-bottom: 1px solid ${Colors['Grey_02_line']};
    padding-left: 21px;

    &::placeholder {
      display: flex;
      align-items: center;
      font-size: ${subFontSize}px !important;
    }
  `;
  const searchBtn = css`
    display: flex;
    margin: 8px auto;
    font-weight: bold;
    font-size: ${miniFontSize}px;
    background-color: inherit;
  `;

  const info = css`
    display: flex;
    padding: 13px 21px 0 28px;
  `;

  const rectangleWrapper = css`
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    gap: 5px;
    margin-left: 15px;
  `;

  const rectangle = css`
    display: flex;
    background-color: red;
    width: 13px;
    height: 13px;
    align-items: center;
    text-align: center;
    justify-content: center;
    vertical-align: center;
  `;
  const changePw = css`
    position: fixed;
    bottom: 0;
    height: 56px;
  `;
  return (
    <div css={wrapper}>
      <div css={top} />
      <HeadView handleClick={handleGoBack} icon={<Icon src={BackArrow} width={`${miniIcons}px`} height={`${miniIcons}px`} />} header={MESSAGES.HEAD_TITLES.PW_CHANGE} />
      <TextFieldView css={password} {...curPwNumber} type={'password'} placeholder={MESSAGES.PLACEHOLDER.CURR_PW} placeholderLeft={true} />

      {curPwHelperText && (
        <HelperTextView
          child={
            <div css={rectangleWrapper}>
              <div css={rectangle} />
              {curPwHelperText}
            </div>
          }
        />
      )}
      <ButtonView cssStyles={searchBtn} handleClick={handleSearchPwBtn} variant={'secondary'} text={MESSAGES.BUTTON.FIND_PW} width={'fit-content'} />
      <TextFieldView css={password} {...newPwNumber} type={'password'} placeholder={MESSAGES.PLACEHOLDER.NEW_PW} placeholderLeft={true} />
      <TextFieldView css={password} {...confirmPwNumber} type={'password'} placeholder={MESSAGES.PLACEHOLDER.CONFIRM_NEW_PW} placeholderLeft={true} />
      {newHelperText && (
        <HelperTextView
          child={
            <div css={rectangleWrapper}>
              <div css={rectangle} />
              {newHelperText}
            </div>
          }
        />
      )}
      {confirmHelperText && (
        <HelperTextView
          child={
            <div css={rectangleWrapper}>
              <div css={rectangle} />
              {confirmHelperText}
            </div>
          }
        />
      )}
      <div css={info}>
        <TypographyView fontSize={miniFontSize} color={'Grey_05_subtext'}>
          {MESSAGES.INFORMATION.PW_VALIDATION_INFO}
        </TypographyView>
      </div>
      <ButtonView handleClick={handlePwChangeButton} size={56} rounded={'0'} cssStyles={changePw} variant={`${disabled ? 'disable' : 'primary'}`} disabled={disabled} text={MESSAGES.BUTTON.EDIT} />
    </div>
  );
};

export default ProfileChangePasswordView;
