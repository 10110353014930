import React from "react";
import MESSAGES from "../../../constants/messages";
import { css } from "@emotion/react";
import Colors from "../../../types/colors";
import { ArrowDown } from "../../../assets/images/svg";

interface DropdownViewProps {
  value: string;
  onChange:(e: { target: { value: string; }; })=>void
}

const DropdownView = ({value, onChange}:DropdownViewProps) => {

  const dropdownWrapper = css`
   background-color: ${Colors['Grey_01_bg']};
`
  const dropdown = css`
    display: flex;
    margin: 0 auto;
    border: none !important;
    width: 21vw;
    color: ${Colors['Grey_05_subtext']};
    background-color: ${Colors['Grey_01_bg']};
    font-size: 14px;
    padding: 15px 0;
    appearance: none;
    background-image: url(${ArrowDown});
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 12px 12px;
    &::-ms-expand {
      display: none !important;
    }
    outline: none !important;
    &:focus {
      outline: none !important;
    }
  `

  const option = css`
      border: none !important;
      background-color: #fff;
      color: #333;
      padding: 8px 16px;
      outline: none !important;
    &:focus {
      outline: none !important;
    }
  `
  return (
    <div css={dropdownWrapper}>
      <select css={dropdown} value={value} onChange={onChange}>
        <option css={option} value="unread">{MESSAGES.BUTTON.UNREAD}</option>
        <option css={option} value="all">{MESSAGES.BUTTON.SHOW_ALL}</option>
      </select>
    </div>
  );
};

export default DropdownView;