import React, { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AskQuestionView from './ask-question.view';
import { EtcApi } from '../../../../../apis/v1/etc.api';
import useAuthentication from '../../../../../store/useAuthentication';

const AskQuestion = () => {
  const { authentication } = useAuthentication();
  const etcApi = new EtcApi(process.env.REACT_APP_ENV !== 'dev', authentication.accessToken, authentication.deviceId);
  const navigate = useNavigate();
  const [titleValue, setTitleValue] = useState('');
  const [contentValue, setContentValue] = useState('');
  const [disable, setDisable] = useState(true);
  const handleTitleChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setTitleValue(event.target.value);
  };

  const handleContentChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setContentValue(event.target.value);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (titleValue.length === 0 || contentValue.length === 0) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [titleValue, contentValue]);
  const handleAddQuestionBtn = async () => {
    const date = new Date();
    const options = { timeZone: 'Asia/Seoul', hour12: false };
    const year = date.toLocaleString('en-US', { year: 'numeric', ...options });
    const month = date.toLocaleString('en-US', { month: '2-digit', ...options });
    const day = date.toLocaleString('en-US', { day: '2-digit', ...options });
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    const milliseconds = date.getMilliseconds().toString().padStart(3, '0');
    const localDateAndTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
    const body = {
      vocTitle: titleValue,
      vocContent: contentValue,
      vocAt: localDateAndTime,
    };
    try {
      const { data }: any = await etcApi.postVocAsync({ body });

      if (data?.message === 'success') {
        await window.flutter_inappwebview.callHandler('showToastMessage', '질문이 등록되었습니다.');
        navigate(-1);
      } else if (data.message.includes('ERR-400')) {
        await window.flutter_inappwebview.callHandler('showToastMessage', '메세지 등록이 실패하였습니다.');
      } else if (data.includes('ERR-500')) {
        await window.flutter_inappwebview.callHandler('goErrorPage', '');
      }
    } catch (error) {
      // await window.flutter_inappwebview.callHandler('goErrorPage', '');
    }
  };

  const props = {
    handleGoBack,
    handleTitleChange,
    handleContentChange,
    handleAddQuestionBtn,
    contentValue,
    disable,
  };

  return <AskQuestionView {...props} />;
};

export default AskQuestion;
