import React from 'react';
import TypographyView from './typography.view';
import { css } from '@emotion/react';
import Colors from '../../../types/colors';
import { Link } from 'react-router-dom';
import convertDate from '../../../utils/dateConverter';
import useDpi from '../../../hooks/useDpi';

interface MessageViewProps {
  maxLines?: number;
  height?: any;
  message: any;
  to?: string;
}

const MessageView = ({ maxLines, height, message, to }: MessageViewProps) => {
  const { subFontSize, miniFontSize } = useDpi();

  const wrapper = css`
    display: flex;
    flex-direction: column;

    justify-content: center;
    height: ${height / 7}px;
    //height: 93px;
    padding: 0 21px;
    background-color: ${Colors.white_regular};
    border-bottom: 1px solid ${Colors.Grey_02_line};
  `;

  const typographyWrapper = css`
    display: flex;
    flex-direction: column;

    gap: 6.3px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: ${maxLines};
    -webkit-box-orient: vertical;
    text-decoration: none;
    // border-top: 1px solid ${Colors['Grey_02_line']};
  `;
  const date = css`
    opacity: 0.5;
    color: ${Colors['Grey_05_subtext']};
  `;
  const messageStyle = css`
    color: ${Colors['Grey_05_subtext']};
  `;
  return (
    <div css={wrapper}>
      <Link css={typographyWrapper} key={message.messageId} style={{ opacity: message.isRead ? 0.5 : 1 }} to={to || ''}>
        <TypographyView cssStyles={date} fontSize={miniFontSize}>
          {convertDate(message?.messageAt || message?.sendAt)}
        </TypographyView>
        <TypographyView cssStyles={messageStyle} fontSize={subFontSize}>
          {message?.messageTitle || message?.content}
        </TypographyView>
      </Link>
    </div>
  );
};

export default MessageView;
