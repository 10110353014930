import React, { ChangeEvent, ChangeEventHandler } from 'react';
import HeadView from '../../../../molecules/common/head.view';
import TextFieldView from '../../../../atoms/inputs/text-field.view';
import TextareaView from '../../../../atoms/inputs/textarea.view';
import { css } from '@emotion/react';
import TypographyView from '../../../../atoms/foundation/typography.view';
import Colors from '../../../../../types/colors';
import BackArrow from '../../../../../assets/images/svg/icons_arrow_back.svg';
import Icon from '../../../../atoms/foundation/icon.view';
import useDpi from '../../../../../hooks/useDpi';

interface AskQuestionViewProps {
  handleGoBack: () => void;
  handleContentChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  contentValue: string;
  handleAddQuestionBtn: () => void;
  handleTitleChange: ChangeEventHandler<HTMLInputElement>;
  disable: boolean;
}
const AskQuestionView = ({ disable, handleGoBack, handleContentChange, handleTitleChange, contentValue, handleAddQuestionBtn }: AskQuestionViewProps) => {
  const { miniIcons, listTitle } = useDpi();
  const wrapper = css`
    display: flex;
    flex-direction: column;
    //padding: 15px;
    height: 95vh;
    width: 100%;
    background-color: white;
    margin-top: 50px;
  `;

  const title = css`
    padding-bottom: 13px;
    border-bottom: 1px solid ${Colors['Grey_02_line']};
  `;

  const inputStyle = css`
    font-size: ${listTitle}px;
    font-weight: 600;
    color: ${Colors.Grey_03_regular};
  `;

  const textArea = {
    maxLength: 1000,
    placeholder: '질문을 입력해주세요.',
  };

  const typography = css`
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 15px;
    color: ${Colors['Grey_03_regular']};
    font-size: 14px;
  `;
  return (
    <div css={wrapper}>
      <HeadView
        handleClick={handleGoBack}
        icon={<Icon src={BackArrow} width={`${miniIcons}px`} height={`${miniIcons}px`} />}
        header={'질문하기'}
        isActionAdd={true}
        disable={disable}
        handleAction={handleAddQuestionBtn}
      />
      <div style={{ padding: '15px' }}>
        <TextFieldView css={inputStyle} boxStyle={title} onChange={handleTitleChange} placeholderLeft={true} placeholder={'제목'} />
        <TextareaView {...textArea} onChange={handleContentChange} />
        <TypographyView cssStyles={typography}>{contentValue.length}/1000</TypographyView>
      </div>
    </div>
  );
};

export default AskQuestionView;
